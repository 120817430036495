import MainTitle from 'components/MainTitle'
import { Input } from 'components/form/input'
import { Button } from 'components/ui/button'
import { Form, Formik } from 'formik'
import { RotateCcw } from 'lucide-react'
import React from 'react'
import { createCompanySchema } from 'schemas/companySchemas'
import { useCreateCompany } from 'store/server/useCompany'

export default function AddCompany() {
  const { mutateAsync, isPending } = useCreateCompany()

  const handleSubmit = async (values) => {
    mutateAsync(values)
  }

  return (
    <div>
      <MainTitle back>Yeni Firma Ekle</MainTitle>
      <Formik
        initialValues={{
          name: '',
          address: '',
          email: '',
          code: '',
          authorizedFullName: '',
          authorizedPhone: ''
        }}
        onSubmit={handleSubmit}
        validationSchema={createCompanySchema}
      >
        <Form className="space-y-5 bg-white p-5 rounded">
          <Input name="name" label="Firma İsmi" autoComplete="off" />
          <Input name="address" label="Adres" autoComplete="off" />
          <div className="grid grid-cols-2 gap-5">
            <Input name="email" label="E-posta" autoComplete="off" />
            <Input name="code" label="Code" autoComplete="off" />
          </div>
          <div className="grid grid-cols-2 gap-5">
            <Input name="authorizedFullName" label="Yetkili Adı Soyadı" autoComplete="off" />
            <Input name="authorizedPhone" label="Yetkili Telefon" autoComplete="off" />
          </div>
          <div className="flex justify-center">
            <Button type="submit" className="w-80" disabled={isPending}>
              {isPending && <RotateCcw className="animate-spin mr-2" size={20} />}
              Ekle
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  )
}
