import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useOrderUpdateStatus } from 'store/server/useOrder'
import { Button } from 'components/ui/button'

export default function CardItem({ data }) {
  const navigate = useNavigate()
  const { mutate } = useOrderUpdateStatus()
  const [brand, department, subDepartment] = data.department.split(' / ')

  const handleGo = () => {
    navigate(
      `/siparisler/ekle/${data.brandId}_${brand}/${data.sizeGroup.departmentId}_${department}/${subDepartment}/${data.id}`
    )
  }

  const handleUpdateStatus = (status) => {
    mutate({ id: data.id, body: { status } })
  }

  return (
    <div className="grid grid-cols-[1fr_1fr_2fr_repeat(6,1fr)_80px] gap-x-5 bg-background rounded-lg p-5 items-center border-2 border-gray-400 border-opacity-30 hover:border-opacity-100 border-dashed transition-all duration-500 ease-in-out text-sm">
      <div>
        <p>{format(new Date(data.updatedAt), 'dd.MM.yyyy HH:mm')}</p>
      </div>
      <div>
        <p>{data.orderNo}</p>
      </div>
      <div>
        <p>{data.company.name}</p>
      </div>
      <div>
        <p>{data.brandOrderNo}</p>
      </div>
      <div>
        <p>{data.supplierNo}</p>
      </div>
      <div>
        <p>{data.articleNo}</p>
      </div>
      <div>
        <p>{data.brand.name}</p>
      </div>
      <div>
        <p>{data.country.name}</p>
      </div>
      <div>
        <p>{data.sizeGroup.name}</p>
      </div>
      <div className="justify-self-end space-y-1 flex flex-col justify-end">
        <Button size="sm" variant="blue" onClick={handleGo}>
          Düzenle
        </Button>
        {data.status === 'WAITING' && (
          <Button size="sm" variant="green" onClick={() => handleUpdateStatus('IN_PRODUCTION')}>
            Onayla
          </Button>
        )}
        {data.status === 'WAITING' && (
          <Button size="sm" variant="destructive" onClick={() => handleUpdateStatus('CANCELLED')}>
            İptal
          </Button>
        )}
        {data.status === 'IN_PRODUCTION' && (
          <Button size="sm" variant="yellow" onClick={() => handleUpdateStatus('WAIT')}>
            Beklet
          </Button>
        )}
      </div>
    </div>
  )
}
