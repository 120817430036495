import React, { useState } from 'react';
import { Input } from 'components/form/input';
import { SelectCurrency } from 'components/form/compobox/select-priceand-unit';
import CheckboxCustom from 'components/form/checkboxone';
import { FieldArray, useFormikContext } from 'formik'
export default function PriceSection({idx}) {
  // Her bir fiyat girişi için ayrı state'ler
  const { values, setFieldValue } = useFormikContext()

  const [currency1, setCurrency1] = useState('');
  const [price1, setPrice1] = useState('');
  const [currency2, setCurrency2] = useState('');
  const [price2, setPrice2] = useState('');
  const [currency3, setCurrency3] = useState('');
  const [price3, setPrice3] = useState('');
  const [currency4, setCurrency4] = useState('');
  const [price4, setPrice4] = useState('');

  // Her bir input için ayrı handleChange fonksiyonları
  const handleChangeCurrency1  = (event) => setCurrency1(event.target.value);
  const handleChangePrice1 = (event) => setPrice1(event.target.value);
  const handleChangeCurrency2  = (event) => setCurrency2(event.target.value);
  const handleChangePrice2 = (event) => setPrice2(event.target.value);
  const handleChangeCurrency3  = (event) => setCurrency3(event.target.value);
  const handleChangePrice3 = (event) => setPrice3(event.target.value);
  const handleChangeCurrency4  = (event) => setCurrency4(event.target.value);
  const handleChangePrice4 = (event) => setPrice4(event.target.value);



  return (
    <div className="space-y-2 border border-dashed p-2 rounded">
      <div className="mb-2 grid grid-cols-2 gap-x-5 text-sm font-medium text-simsek">
        <p>Fiyat</p>
        <p>Döviz</p>
      </div>
      <div className="mb-2 grid grid-cols-2 gap-x-2 text-sm font-medium text-simsek">
      <Input
  name={`priceLabel[${idx}].mix[0].currency`}
  autoComplete="off"
  value={values.priceLabel[idx].mix[0]?.currency}
  onChange={(e) => {
    const value = e.target.value;
    // Nokta veya virgül ile ondalık ayırıcıyı kabul et
    const normalizedValue = value.replace(/,/g, '.'); // Tüm virgülleri noktaya çevir
    if (/^\d*\.?\d*$/.test(normalizedValue) || value === '') {
      // Doğrudan normalizedValue'yi kullanarak backend'e gönderilecek veriyi dönüştürün
      setFieldValue(`priceLabel[${idx}].mix[0].currency`, normalizedValue);
    }
  }}
/>
        <SelectCurrency name="currency1" idx={idx} />
      </div>
      <div className="mb-2 grid grid-cols-2 gap-x-2 text-sm font-medium text-simsek">
        <Input
          name="priceLabel[${idx}].mix[1].currency"
          autoComplete="off"
          value={values.priceLabel[idx].mix[1]?.currency}
          onChange={(e) => {
    const value = e.target.value;
    // Nokta veya virgül ile ondalık ayırıcıyı kabul et
    const normalizedValue = value.replace(/,/g, '.'); // Tüm virgülleri noktaya çevir
    if (/^\d*\.?\d*$/.test(normalizedValue) || value === '') {
      // Doğrudan normalizedValue'yi kullanarak backend'e gönderilecek veriyi dönüştürün
      setFieldValue(`priceLabel[${idx}].mix[1].currency`, normalizedValue);
    }
  }}
        />
        <SelectCurrency name="currency2" idx={idx} />
      </div>
      <div className="mb-2 grid grid-cols-2 gap-x-2 text-sm font-medium text-simsek">
        <Input
          name="priceLabel[${idx}].mix[2].currency"
          autoComplete="off"
          value={values.priceLabel[idx].mix[2]?.currency}
          onChange={(e) => {
    const value = e.target.value;
    // Nokta veya virgül ile ondalık ayırıcıyı kabul et
    const normalizedValue = value.replace(/,/g, '.'); // Tüm virgülleri noktaya çevir
    if (/^\d*\.?\d*$/.test(normalizedValue) || value === '') {
      // Doğrudan normalizedValue'yi kullanarak backend'e gönderilecek veriyi dönüştürün
      setFieldValue(`priceLabel[${idx}].mix[2].currency`, normalizedValue);
    }
  }}
        />
        <SelectCurrency name="currency3" idx={idx}/>
      </div>
      <div className="mb-2 grid grid-cols-2 gap-x-2 text-sm font-medium text-simsek">
        <Input
          name="priceLabel[${idx}].mix[3].currency"
          autoComplete="off"
          value={values.priceLabel[idx].mix[3]?.currency}
          onChange={(e) => {
    const value = e.target.value;
    // Nokta veya virgül ile ondalık ayırıcıyı kabul et
    const normalizedValue = value.replace(/,/g, '.'); // Tüm virgülleri noktaya çevir
    if (/^\d*\.?\d*$/.test(normalizedValue) || value === '') {
      // Doğrudan normalizedValue'yi kullanarak backend'e gönderilecek veriyi dönüştürün
      setFieldValue(`priceLabel[${idx}].mix[3].currency`, normalizedValue);
    }
  }}
        />
        <SelectCurrency name="currency4" idx={idx} />
      </div>
      <CheckboxCustom label="Siparişte RFID 'li etiket var mı ?" name="termsAccepted" idx={idx} />
    </div>
  );
}
