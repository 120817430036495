import { useBrandList } from 'store/server/useBrand'

import MainTitle from 'components/MainTitle'
import Spinner from 'components/spinner'
import { BASE_URL2 } from 'config/axios'

export default function Brand() {
  const { data, isFetching } = useBrandList()

  if (isFetching) return <Spinner className="mt-20" />

  return (
    <div>
      <div className="mb-5">
        <MainTitle>Markalar</MainTitle>
      </div>
      <div className="grid grid-cols-3 gap-5">
        {data.map((data) => (
          <div
            key={data.id}
            className="grid bg-gray-100 rounded-lg p-5 items-center border-2 border-gray-400 border-opacity-0 hover:border-opacity-100 border-dashed transition-all duration-500 ease-in-out cursor-pointer"
          >
            <div className="justify-self-start w-full">
              <div className="font-semibold text-s-blue ">
                <div>{data?.name}</div>
              </div>
            </div>
            <div className="justify-self-center">
              <img src={`${BASE_URL2}${data?.logoPath}`} className="h-10" alt="marka ismi" />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
