import { useState } from 'react'

import { useCompany } from 'store/server/useCompany'

import CardItem from './CardItem'
import { Input } from 'components/ui/input'
import Spinner from 'components/spinner'
import MainTitle from 'components/MainTitle'
import { Button } from 'components/ui/button'
import { Link } from 'react-router-dom'

export default function Company() {
  const [filterText, setFilterText] = useState('')
  const { data, isFetching } = useCompany()

  if (isFetching) return <Spinner className="mt-20" />

  const filteredData = data.filter((item) =>
    item.name?.toLowerCase().includes(filterText.toLowerCase())
  )

  return (
    <div>
      <div className="flex justify-end">
        <Link to="/firmalar/ekle">
          <Button variant="blue">Yeni Firma Ekle</Button>
        </Link>
      </div>
      <div className="sticky top-0 pt-4 bg-white z-0">
        <div className="flex justify-between">
          <MainTitle>Firmalar</MainTitle>
          <div className="w-[300px]">
            <Input
              placeholder="Firma Ara..."
              className="bg-white"
              onChange={(e) => setFilterText(e.target.value)}
            />
          </div>
        </div>
        <div className="grid grid-cols-[1fr_1fr_1fr_10px] gap-x-5 px-5 py-4 items-center text-sm">
          <div className="justify-self-start">Firma Bilgisi</div>
          <div className="justify-self-center">Yetkili Kişi Adı Soyadı</div>
          <div className="justify-self-center">Kod</div>
          <div></div>
        </div>
      </div>
      <div className="xl:text-sm 2xl:text-base">
        {data?.length < 1 && (
          <div className="text-4xl font-extralight text-sky-900">Kayıt Bulunamadı...</div>
        )}
        <div className="grid gap-5">
          {filteredData?.map((item) => (
            <CardItem data={item} key={item.id} />
          ))}
        </div>
      </div>
    </div>
  )
}
