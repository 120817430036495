import React from 'react'

export default function TotalCard({ title, count }) {
  return (
    <div className="bg-[#9EA2A6] rounded-lg p-2 flex items-end justify-between shadow-lg curve bg-no-repeat bg-cover bg-center h-[130px]">
      <p className="text-xl font-thin text-gray-500">{title}</p>
      <p className="text-4xl font-semibold text-gray-700">{count}</p>
    </div>
  )
}
