import { FieldArray, useFormikContext } from 'formik'

import { SelectAdditionalInstructions } from 'components/form/compobox/select-additional-instructions'
import { Button } from 'components/ui/button'
import { Label } from 'components/ui/label'

export default function AdditionalInstructions({ idx }) {
  const { values } = useFormikContext()
  return (
    <FieldArray name={`washingLabel[${idx}].additionalPhrases`}>
      {({ push }) => (
        <div>
          <Label className="text-simsek text-sm font-medium">Ek İbareler</Label>
          <div className="space-y-2">
            {values.washingLabel[idx].additionalPhrases.map((_, idx2) => (
              <div key={idx2}>
                <SelectAdditionalInstructions
                  idx={idx}
                  idx2={idx2}
                  name={`washingLabel[${idx}].additionalPhrases[${idx2}].id`}
                />
              </div>
            ))}
          </div>
          <div className="col-span-3 flex justify-center items-center mt-2">
            <Button type="button" onClick={() => push({ id: '' })} variant="ghost">
              + Ek İbare Ekle
            </Button>
          </div>
        </div>
      )}
    </FieldArray>
  )
}
