import React, { useState, useEffect, useRef } from "react";

const RenderIndoItemsKıds = ({ indo, startIndex, indexxx }) => {
  const [scale, setScale] = useState(1);
  const containerRef = useRef(null); // Kapsayıcı referansı
  const contentRef = useRef(null); // İçerik referansı
  const innerRef = useRef(null); // İçerik referansı

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current && contentRef.current) {
        const containerWidth = containerRef.current.offsetWidth; // Kapsayıcı genişliği
        const contentWidth = contentRef.current.scrollWidth; // İçerik genişliği
        console.log("innerrefff",innerRef.current.scrollWidth)
        if (containerWidth > 200) {
          const excessWidth = containerWidth - 200;
          const scaleFactor = (containerWidth - excessWidth) / containerWidth;
          setScale(scaleFactor);
        } else if (contentWidth > containerWidth) {
          setScale(containerWidth / contentWidth);
        } else {
          setScale(1); // Orijinal boyuta dön
        }
      }
    };

    const resizeObserver = new ResizeObserver(() => handleResize());
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    handleResize(); // İlk kontrol

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        ref={containerRef}
        style={{

          overflow: "hidden",
          maxWidth: "145px",
          marginLeft:5,
          display: "flex",

        }}
      >
        <div
          ref={contentRef}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "nowrap",
            transform: `scaleX(${scale})`,
            transformOrigin: "left",
            transition: "transform 0.3s ease",
            margin: "0 auto",
            gap:"1mm"

          }}
        >
          {indo.map((item, index) => {
            // `indexxx` içindeki mevcut sırayı kontrol et
            const isHighlighted = startIndex === indexxx[index];

            return (
              <div
                key={index}
                ref={innerRef}
                style={{
                  height: "4mm",
                  minWidth: "4mm",
                  padding: 3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: isHighlighted ? "black" : "transparent",
                  color: isHighlighted ? "white" : "black",
                  textAlign: "center",
                }}
              >
                <p
                  className="helve-condensed"
                  style={{ fontSize: isHighlighted ? "7.5pt" : "7pt", margin: 0 , flexWrap: "nowrap",whiteSpace: "nowrap",fontWeight:isHighlighted ? 500 : 400}}
                >
                  {item?.name}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RenderIndoItemsKıds;
