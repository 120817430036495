import React, { useEffect, useState } from 'react'
import A4Page from '../A4Page'
import TopDetail from '../TopDetail'
import Page1 from './Page1'
import Page2Kids from './Page2Kids'
import { ReactComponent as Zaraön } from '../../../../../assets/Varlık2.svg'
import useOrderStore from 'store/client/orderStore'
import { IoTriangleOutline } from 'react-icons/io5'
import { IoTriangle } from 'react-icons/io5'
import { ReactComponent as ZaraBom } from '../../../../../assets/DD.svg'
import { ReactComponent as ZaraBomRed } from '../../../../../assets/DDRED.svg'

const L55XPR = ({ data, selectColor, tab }, ref) => {
  const department = data.department
  const [brand, category, subCategory] = department.split(' / ')

  function splitCurrency(value) {
    // Sayıyı string'e çevir
    const stringValue = value.toString()

    // Ondalık noktaya göre ayır
    let [integerPart, decimalPart] = stringValue.split('.')

    // Ondalık kısmı kontrol et ve düzenle
    if (!decimalPart) {
      // Ondalık kısım yoksa, 00 olarak ayarla
      decimalPart = '00'
    } else if (decimalPart.length === 1) {
      // Ondalık kısım tek haneliyse, sonuna bir 0 ekle
      decimalPart += '0'
    } else if (decimalPart.length > 2) {
      // Ondalık kısım iki haneden fazlaysa, ilk iki haneyi al
      decimalPart = decimalPart.substring(0, 2)
    }

    // Sonuçları döndür
    return [integerPart, decimalPart]
  }
  function getCurrencySymbol(type) {
    switch (type) {
      case 'AUD':
        return '$' // Avustralya Doları için dolar işareti
      case 'USD':
        return '$' // Avustralya Doları için dolar işareti
      case 'EUR':
        return '€' // Euro için euro işareti
      case 'BRL':
        return 'R$' // Brezilya Reali için Real işareti
      case 'GBP':
        return '£' // Brezilya Reali için Real işareti
        case 'CLP':
          return '£' // Brezilya Reali için Real işareti
      default:
        return '' // Diğer durumlar için boş string (veya belki başka bir varsayılan sembol)
    }
  }
  const { referance } = useOrderStore()
  const [currentShape, setCurrentShape] = useState(null)

  const isBlack = referance?.backgroundColor === 'B'

  const [integerPart, decimalPart] = splitCurrency(data.priceLabel[0].mix[0].currency)
  const [integerPart2, decimalPart2] = splitCurrency(data.priceLabel[0].mix[1].currency)
  const currencySymbol = getCurrencySymbol(data.priceLabel[0].mix[0].type)
  const currencySymbol2 = getCurrencySymbol(data.priceLabel[0].mix[1].type)
  
  let integerPart3, decimalPart3, currencySymbol3, integerPart4, decimalPart4, currencySymbol4

  if (data.priceLabel[0].mix.length > 2) {
    ;[integerPart3, decimalPart3] = splitCurrency(data.priceLabel[0].mix[2].currency)
    currencySymbol3 = getCurrencySymbol(data.priceLabel[0].mix[2].type)
  } else {
    integerPart3 = decimalPart3 = currencySymbol3 = ''
  }

  if (data.priceLabel[0].mix.length > 3) {
    ;[integerPart4, decimalPart4] = splitCurrency(data.priceLabel[0].mix[3].currency)
    currencySymbol4 = getCurrencySymbol(data.priceLabel[0].mix[3].type)
  } else {
    integerPart4 = decimalPart4 = currencySymbol4 = ''
  }

  // item.amount > 0 koşulunu sağlayan öğeleri filtrele ve her 8 öğede bir grupla
  const filteredAndGroupedSizes = data.priceLabel[selectColor].sizes
    .filter((item) => item.amount > 0)
    .reduce((acc, item, index) => {
      const groupIndex = Math.floor(index / 3) // Her 3 öğe için bir grup indeksi
      if (!acc[groupIndex]) {
        acc[groupIndex] = { items: [], startIndex: index - (index % 3) } // Yeni bir grup oluştur
      }
      acc[groupIndex].items.push(item)
      return acc
    }, [])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} ref={ref}>
      {filteredAndGroupedSizes.map((groupObj, groupIndex) => (
        <A4Page key={groupIndex} type="yatay">
          <div className="space-y-5" style={{ position: 'relative' }}>
            {category === 'Kids' ? (
              // Eğer category === "Kids" ise bu div render edilecek
              <div
                style={{
                  width: '55.2mm',
                  height: '95.2mm',
                  border: '1px solid black',
                  position: 'absolute',
                  top: '135px',
                  left: `26px`
                }}
              ></div>
            ) : (
              // Eğer category !== "Kids" ise bu div render edilecek
              <div
                style={{
                  width: '55.2mm',
                  height: '110.2mm',
                  border: '1px solid black',
                  position: 'absolute',
                  top: '135px',
                  left: `26px`
                }}
              ></div>
            )}
            <TopDetail values={data} selectColor={selectColor} tab={tab} />
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 22 }}>
            {category === 'Kids' ? (  <div
                className="printable"
                style={{
                  position: 'relative',
                  marginRight: 16,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: 104
                }}
              >
                {isBlack ? (
                  <Zaraön style={{ width: '37mm', height: '15.438mm' }} />
                ) : (
                  <Zaraön style={{ width: '37mm', height: '15.438mm' }} />
                )}
                <div
                  style={{
                    position: 'absolute',
                    top: '25px',
                    left: '45.64%',
                    background: 'none'
                  }}
                >
                  <ZaraBomRed width="4.4mm" height="4.4mm"></ZaraBomRed>
                </div>
              </div>  ) : ( <div
                className="printable"
                style={{
                  position: 'relative',
                  marginRight: 16,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: 157
                }}
              >
                {isBlack ? (
                  <Zaraön style={{ width: '37mm', height: '15.438mm' }} />
                ) : (
                  <Zaraön style={{ width: '37mm', height: '15.438mm' }} />
                )}
                <div
                  style={{
                    position: 'absolute',
                    top: '25px',
                    left: '45.64%',
                    background: 'none'
                  }}
                >
                  <ZaraBomRed width="4.4mm" height="4.4mm"></ZaraBomRed>
                </div>
              </div>  )}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                  gap: '20px',
                  marginRight: 12
                }}
              >
                <div
                  style={{
                    width: '31mm',
                    height: '12mm',
                    borderRadius: '5px',
                    borderColor: '#6d6b6e',
                    padding: '5px', // Padding değerini azalttım
                    display: 'grid',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid',
                    overflow: 'hidden' // Taşmayı engellemek için
                  }}
                >
                  <div
                    style={{
                      height: '0.9mm',
                      backgroundColor: '#c8102e',
                      width: '25mm',
                      margin: 'auto', // Bu, div'i yatay olarak ortalar
                      marginTop: '3px'
                    }}
                  ></div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                      justifyContent: 'center',
                      marginTop: '-3px'
                    }}
                  >
                    <p className="helve" style={{ fontSize: '17pt', marginRight: '13px' }}>
                      {currencySymbol}
                    </p>
                    <p className="helve" style={{ fontSize: '17pt' }}>
                      {integerPart}
                    </p>
                    <p
                      className="helve"
                      style={{ fontSize: '10pt', marginTop: '4px', marginRight: '4px' }}
                    >
                      {decimalPart}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    width: '37mm',
                    height: '20mm',
                    borderRadius: '5px',
                    borderColor: '#6d6b6e',
                    padding: '25px', // Padding değerini azalttım
                    display: 'grid',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid',
                    overflow: 'hidden' // Taşmayı engellemek için
                  }}
                >
                  <div
                    style={{
                      height: '0.9mm',
                      backgroundColor: '#003da5',
                      width: '22.5mm',
                      margin: 'auto', // Bu, div'i yatay olarak ortalar
                      marginTop: '-3px'
                    }}
                  ></div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                      justifyContent: 'center'
                    }}
                  >
                    <p className="helve" style={{ fontSize: '17pt', marginRight: '13px' }}>
                      {currencySymbol2}
                    </p>
                    <p className="helve" style={{ fontSize: '17pt' }}>
                      {integerPart2}
                    </p>
                    <p
                      className="helve"
                      style={{ fontSize: '10pt', marginTop: '4px', marginRight: '4px' }}
                    >
                      {decimalPart2}
                    </p>
                  </div>
                </div>
              </div>
              {groupObj.items.map((sizeData, index) => (
                <div>
                  {category === 'Kids' ? (
                    <div
                      style={{
                        width: '55.2mm',
                        height: '95.2mm',
                        border: '1px solid red',
                        position: 'absolute',
                        top: '134px',
                        right: `${493 - index * 230}px`
                      }}
                    ></div>
                  ) : (
                    <div
                      style={{
                        width: '55.2mm',
                        height: '110.2mm',
                        border: '1px solid red',
                        position: 'absolute',
                        top: '134px',
                        right: `${493 - index * 230}px`
                      }}
                    ></div>
                  )}

                  <div className="printable" style={{ position: 'relative', marginRight: 10 }}>
                    {category === 'Kids' ? (
                      <Page2Kids
                        key={groupObj.startIndex + index} // Global index kullanımı
                        selectColor={selectColor}
                        barcodeValue={`0${data?.articleNo?.split('/')[0]}${data?.articleNo?.split('/')[1]}${data?.priceLabel[selectColor]?.colorCode}${sizeData.sCode ? sizeData.sCode : ''}`}
                        data={data}
                        sizeData={sizeData}
                        indo={data?.priceLabel[0]?.sizes}
                        szrgrp={data?.sizeGroup}
                        inx={groupObj.startIndex + index} // Global index kullanımı
                        integerpart3={integerPart3}
                        currencysymbol3={currencySymbol3}
                        decimalpart3={decimalPart3}
                        decimalpart4={decimalPart4}
                        integerpart4={integerPart4}
                        currencysymbol4={currencySymbol4}
                        rifd={data.priceLabel[0].rifd}
                      />
                    ) : (
                      <Page1
                        key={groupObj.startIndex + index} // Global index kullanımı
                        selectColor={selectColor}
                        barcodeValue={`0${data?.articleNo?.split('/')[0]}${data?.articleNo?.split('/')[1]}${data?.priceLabel[selectColor]?.colorCode}${sizeData.sCode ? sizeData.sCode : ''}`}
                        data={data}
                        sizeData={sizeData}
                        indo={data?.priceLabel[0]?.sizes}
                        szrgrp={data?.sizeGroup}
                        inx={groupObj.startIndex + index} // Global index kullanımı
                        integerpart3={integerPart3}
                        currencysymbol3={currencySymbol3}
                        decimalpart3={decimalPart3}
                        decimalpart4={decimalPart4}
                        integerpart4={integerPart4}
                        currencysymbol4={currencySymbol4}
                        rifd={data.priceLabel[0].rifd}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </A4Page>
      ))}
    </div>
  )
}

export default React.forwardRef(L55XPR)
