import { useNavigate } from 'react-router-dom'

import { useSubDepartmentList } from 'store/server/useBrand'

import Spinner from 'components/spinner'
import { useParams } from 'react-router-dom'
import MainTitle from 'components/MainTitle'

export default function ChoiceSubDept() {
  const navigate = useNavigate()

  const { dept, brand } = useParams()
  const deptId = dept.split('_')[0]

  const { data, isFetching } = useSubDepartmentList(deptId)
  if (isFetching) return <Spinner />

  const nextStep = (name) => {
    navigate(`${name}`)
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <MainTitle back>Sipariş Ekle</MainTitle>
        <p className="font-bold uppercase">
          {brand.split('_')[1]} / {dept.split('_')[1]}
        </p>
      </div>
      <div className="grid grid-cols-4 gap-5">
        {data.map((data, idx) => (
          <div
            key={idx}
            onClick={() => nextStep(data.name)}
            className="grid bg-gray-100 h-24 rounded-lg p-5 items-center border-2 border-gray-400 border-opacity-0 hover:border-opacity-100 border-dashed transition-all duration-500 ease-in-out cursor-pointer"
          >
            <div className="justify-self-start w-full">
              <div className="font-semibold text-s-blue ">
                <p className="">{data.name}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
