import { Navigate, Outlet } from 'react-router-dom'

import { X } from 'lucide-react'
import { useAuth } from 'store/client/authStore'

import logo from 'assets/logo.png'

export default function AuthLayout() {
  const { user } = useAuth()

  if (user) return <Navigate to="/" replace />
  return (
    <div className="grid grid-cols-[2fr_3fr] h-screen bg-white">
      <div className="bg-back bg-cover bg-center rounded-[24px] m-2 text-white" />
      <div className="flex justify-center items-center">
        <div className="w-[500px] relative">
          <img src={logo} alt="logo" className="w-20 h-20 mx-auto mb-5" />
          <Outlet />
        </div>
      </div>
      <div className="fixed top-10 right-10">
        <div className="rounded-lg p-1 hover:bg-gray-200 cursor-pointer">
          <X size={24} strokeWidth={1.5} />
        </div>
      </div>
    </div>
  )
}
