import { useQuery } from '@tanstack/react-query'
import { useEffect, useState,useMemo } from 'react'
import { useFormikContext } from 'formik'
import requests from 'service/api'
import useTemNewStore from 'store/client/temNewStore'
import SearchSelect from '../search-select'
import { useParams } from 'react-router-dom'

export function SelectBodyChart({ name }) {
  const { values } = useFormikContext()
  const [selected, setSelected] = useState(null)
  const { dept } = useParams()
  const setSpecialValue = useTemNewStore((state) => state.setSpecialValue) // Zustand'dan fonksiyonu çekme
  const setAnotherValue = useTemNewStore((state) => state.setAnotherValue) // Zustand'dan fonksiyonu çekme
  const tabvalue = useTemNewStore((state) => state.tabvalue) // Zustand'dan fonksiyonu çekme


  const { data, isFetched } = useQuery({
    queryKey: ['/list', 'sizeGroup'],
    queryFn: async () => {
      const { data } = await requests.size.listGroup(dept.split('_')[0])
      return data
    },
    placeholderData: []
  })

  const options = useMemo(() => data.map(({ name, id }) => ({
    value: id,
    label: name
  })), [data]);

useEffect(() => {
  if (values.sizeGroup && isFetched) {
    const select = options.find(({ value }) => value === values.sizeGroup)
    setSelected(select);
  }
  // Removed `selected` from the dependency array to avoid the infinite loop
}, [values.sizeGroup, isFetched, options]);

useEffect(() => {
  console.log("tabvalue değişti:", tabvalue);

  // tabvalue'ya göre bir işlem yapılabilir
  if (tabvalue === "0") {
    console.log("Tab 0 seçili");
  } else if (tabvalue === "1") {
    console.log("Tab 1 seçili");
  } else {
    console.log("Diğer bir tab seçili:", tabvalue);
  }
}, [tabvalue]);

useEffect(() => {
  if (selected) {
    if (tabvalue === "0") {
      setSpecialValue(selected.value);
      console.log("speeeeeeeeeeeeeee")
    }
    if (tabvalue === "2") {
      setAnotherValue(selected.value);
      console.log("anooooooooooooooo" +selected.value)

    }

  }
}, [selected, setSpecialValue,]);
  return (
    <SearchSelect
      label="Beden Tablosu"
      options={options}
      name={name}
      value={selected}
      onChange={(e) => setSelected(e)}
    />
  )
}
