import { Checkbox } from 'antd';
import { Label } from 'components/ui/label';
import { useFormikContext } from 'formik';
import { twMerge } from 'tailwind-merge';

export default function CheckboxCustom({ label, name,idx, className, ...props }) {
  const { values, setFieldValue } = useFormikContext();
  const cn = twMerge('flex items-center', className);

  const handleChange = (event) => {
    // Checkbox'ın durumunu güncelle
    setFieldValue(`priceLabel[${idx}].rifd`, event.target.checked);
  };

  return (
    <div className={cn}>
      {label && (
        <Label className="mr-2 text-simsek" htmlFor={name}>
          {label}
        </Label>
      )}
      <Checkbox
        id={name}
        checked={values.priceLabel[idx].rifd}
        onChange={handleChange}
        {...props}
      />
    </div>
  );
}
