import React, { useEffect, useState } from "react";
import QRCodeStyling from "qr-code-styling";
import tshirtlogo from "../../../../../assets/tshirtinfo.svg";

const QRCodeComponent = ({ barcodeValue }) => {
  const targetSizeMm = 20; // Hedef boyut: 17mm
  const mmToPx = 3.779528; // 1mm = 3.779528px
  const targetSizePx = targetSizeMm * mmToPx; // Hedef boyut (piksel)
  const [svgContent, setSvgContent] = useState(""); // Manipüle edilen SVG içeriği
  function transformPathCoordinates(pathData, scaleFactor, xOffset, yOffset) {
    return pathData.replace(/([0-9.]+)[ ,]([0-9.]+)/g, (match, x, y) => {
      const parsedX = parseFloat(x);
      const parsedY = parseFloat(y);
  
      if (isNaN(parsedX) || isNaN(parsedY)) {
        // Eğer x veya y sayı değilse, orijinal eşleşmeyi döndür
        return match;
      }
  
      const scaledX = (parsedX * scaleFactor + xOffset).toFixed(3);
      const scaledY = (parsedY * scaleFactor + yOffset).toFixed(3);
  
      return `${scaledX},${scaledY}`;
    });
  }
  
  useEffect(() => {
    const qrCode = new QRCodeStyling({
      width: targetSizePx,
      height: targetSizePx,
      type: "svg",
      data: `http://www.zara.com/qr/${barcodeValue}`,
      image: tshirtlogo,
      qrOptions: {
        errorCorrectionLevel: "Q",
      },
      dotsOptions: {
        color: "#000000",
        type: "dots",
      },
      cornersSquareOptions: {
        color: "#000000",
        type: "square",
      },
      cornersDotOptions: {
        color: "#000000",
        type: "square",
      },
      backgroundOptions: {
        color: "#ffffff",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        imageSize: 0.3,
      },
    });

    qrCode.getRawData("svg").then((svgBlob) => {
      svgBlob.text().then((svgText) => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
        const svg = svgDoc.documentElement;
    // Tüm otomatik boyutlandırmayı sıfırla
    svg.removeAttribute("width");
    svg.removeAttribute("height");
    svg.removeAttribute("viewBox");
        // SVG'nin boyutlandırmasını manuel olarak ayarla
        svg.setAttribute("width", `${targetSizeMm}mm`);
        svg.setAttribute("height", `${targetSizeMm}mm`);
        svg.setAttribute("viewBox", `0 0 ${targetSizePx} ${targetSizePx}`); // Tam boyut kontrolü için viewBox ayarı
        svg.setAttribute("preserveAspectRatio", "xMidYMid meet");

        // Circle öğelerini manipüle et ve diamond şekline çevir
        const circles = svg.querySelectorAll("circle");
        circles.forEach((circle) => {
          const cx = circle.getAttribute("cx");
          const cy = circle.getAttribute("cy");
          const r = circle.getAttribute("r");

          // Diamond şekli oluştur
          const diamondPath = `M ${cx} ${cy - r} 
            L ${+cx + +r} ${cy} 
            L ${cx} ${+cy + +r} 
            L ${cx - r} ${cy} Z`;

          // Circle'ı diamond ile değiştir
          const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
          path.setAttribute("d", diamondPath);
          path.setAttribute("fill", circle.getAttribute("fill") || "#000");
          path.setAttribute("stroke", circle.getAttribute("stroke") || "none");
          path.setAttribute("stroke-width", circle.getAttribute("stroke-width") || "0");

          circle.parentNode.replaceChild(path, circle);
        });

        const targetRects = [
          { element: svg.querySelector("rect:nth-child(5)"), xOffset: 7.3, yOffset: 7.3 },
          { element: svg.querySelector("rect:nth-child(7)"), xOffset: 59.3, yOffset: 7.3 },
          { element: svg.querySelector("rect:nth-child(9)"), xOffset: 7.3, yOffset: 59.3 },
        ];
        
        // Seçilen öğeleri manipüle et
        targetRects.forEach(({ element, xOffset, yOffset }) => {
          if (element) {
            const originalPath = `M 13 15.988 C 13 16.552, 13.649 21.398, 14.442 26.757 C 19.022 57.694, 19.012 90.399, 14.414 122.343 C 13.556 128.307, 13.017 133.351, 13.218 133.551 C 13.418 133.751, 17.747 133.289, 22.838 132.524 C 55.081 127.680, 99.359 128.124, 128.592 133.586 L 131.683 134.163 130.438 126.332 C 125.494 95.247, 125.719 47.238, 130.939 19.299 C 131.730 15.062, 131.665 14.867, 129.629 15.378 C 128.451 15.674, 121.865 16.716, 114.994 17.694 C 97.351 20.204, 46.808 20.214, 29.500 17.710 C 22.900 16.755, 16.488 15.746, 15.250 15.468 C 14.012 15.190, 13 15.423, 13 15.988 Z`;
        
            // Scale faktörü ve offset değerlerini ayarla
            const scaleFactor = 0.05; // Küçültme faktörü
        
            // Path'i dönüştür
            const newPath = transformPathCoordinates(originalPath, scaleFactor, xOffset, yOffset);
        
            // Path öğesi oluştur ve özelliklerini ekle
            const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
            path.setAttribute("d", newPath);
            path.setAttribute("fill", element.getAttribute("fill") || "#000");
            path.setAttribute("stroke", element.getAttribute("stroke") || "none");
        
            // Eski rect öğesini yeni path ile değiştir
            element.parentNode.replaceChild(path, element);
          }
        });

        // Manipüle edilmiş SVG içeriğini kaydet
        const serializer = new XMLSerializer();
        const manipulatedSvg = serializer.serializeToString(svg);

        const newSvg = `
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100">
          <rect width="100" height="100" fill="white" />
          ${manipulatedSvg}
        </svg>
      `;
      setSvgContent(newSvg);
        setSvgContent(serializer.serializeToString(svg));
      });
    });
  }, [barcodeValue, targetSizePx, targetSizeMm]);

  return (
    <div style={{ width: `${targetSizeMm}mm`, height: `${targetSizeMm}mm`, position: "relative" }}>
    {svgContent && (
      <>
        <iframe
          srcDoc={`<!DOCTYPE html>
          <html lang="en">
            <head>
              <meta charset="UTF-8">
              <title>QR Code</title>
            </head>
            <style>
              body {
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
              }
              svg {
                display: block;
                transform: scale(0.7445); /* Ölçeklemeyi burada ayarlayın */
                transform-origin: center;
              }
            </style>
            <body style="margin:0;display:flex;justify-content:center;align-items:center;height:100%;width:100%;">
              ${svgContent}
            </body>
          </html>`}
          style={{
            border: "none",
            width: "100%",
            height: "100%",
          }}
          title="QR Code"
        />
        {/* QrSquare burada tanımlandı */}
      </>
    )}
  </div>
  );
};

export default QRCodeComponent;



/*import React from "react";
import tshirtlogo from "../../../../../assets/tshirtinfo.svg";
import { QRCodeSVG } from "qrcode.react";

const QRCodeComponent = ({ barcodeValue }) => {
  const qrSize = 49; // QR kodunun boyutu

  return (
    <div style={{ position: "relative", display: "inline-block", width: qrSize, height: qrSize }}>
      <QRCodeSVG
        value={`http://www.zara.com/qr/${barcodeValue}`}
        size={qrSize}
      />
      <img
        src={tshirtlogo}
        alt="Logo"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: `${qrSize * 0.28}px`, // QR kod boyutuna göre logonun genişliği
          height: `${qrSize * 0.28}px`, // QR kod boyutuna göre logonun yüksekliği
        }}
      />
    </div>
  );
};

export default QRCodeComponent;



/*
import React from "react";
import tshirtlogo from "../../../../../assets/tshirtinfo.svg";
import { QRCodeSVG } from "qrcode.react";

const QRCodeComponent = ({ barcodeValue }) => {
  const qrSize = 49; // QR kodunun boyutu

  return (
    <div style={{ position: "relative", display: "inline-block", width: qrSize, height: qrSize }}>
      <QRCodeSVG
        value={`http://www.zara.com/qr/${barcodeValue}`}
        size={qrSize}
      />
      <img
        src={tshirtlogo}
        alt="Logo"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: `${qrSize * 0.28}px`, // QR kod boyutuna göre logonun genişliği
          height: `${qrSize * 0.28}px`, // QR kod boyutuna göre logonun yüksekliği
        }}
      />
    </div>
  );
};

export default QRCodeComponent;
*/