import { forwardRef, useEffect, useState } from 'react'
import useOrderStore from 'store/client/orderStore'
import { twMerge } from 'tailwind-merge'

const LabelCard = ({ children, className, type, number }, ref) => {
  const { referance } = useOrderStore()
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  const isBlackLabel = referance?.backgroundColor === 'B'

  const cn = twMerge(
    `border-[0.5px] border-[#E05AAB] relative text-[4.7pt] font-arial ${
      isBlackLabel && 'bg-black text-white'
    }`,
    className
  )

  useEffect(() => {
    if (referance) {
      setHeight(referance.height)
      setWidth(referance.width)
    }
  }, [referance])

  return (
    <div
      ref={ref}
      className={cn}
      style={{
        width: `${width}mm`,
        height: `${height}mm`
      }}
    >
      <div
        style={{
          right: type === 'front' ? '7mm' : 'auto',
          left: type === 'front' ? 'auto' : '7mm'
        }}
        className={`absolute h-full w-[0.5px]  border-l border-dashed ${
          isBlackLabel ? 'border-white' : 'border-[#2E308D]'
        }`}
      ></div>
      <div
        style={{
          right: type === 'front' ? '1mm' : 'auto',
          left: type === 'front' ? 'auto' : '1mm'
        }}
        className="absolute bottom-0 text-[#E05AAB] bottor"
      >
        {number}
      </div>
      <div
        style={{
          right: type === 'front' ? '1mm' : 'auto',
          left: type === 'front' ? 'auto' : '1mm'
        }}
        className={`absolute top-1/2 h-8 w-1 -translate-y-1/2 ${
          isBlackLabel ? 'bg-white' : 'bg-black'
        }`}
      />
      <div className="flex h-full">
        <div
          style={{
            paddingLeft: type === 'front' ? '0mm' : '7mm',
            paddingRight: type === 'front' ? '7mm' : '0mm'
          }}
          className="w-full"
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default forwardRef(LabelCard)
