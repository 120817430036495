import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

import { SelectBodyChart } from 'components/form/compobox/select-body-chart'
import { SelectCompany } from 'components/form/compobox/select-company'
import { SelectMadeIn } from 'components/form/compobox/select-made-in'
import { Checkbox } from 'components/ui/checkbox'
import { Input } from 'components/form/input'
import { ArticelInput } from 'components/form/articel-input'

export default function TopSection() {
  const { setFieldValue, values } = useFormikContext()
  const [changesForEveryProduct, setChangesForEveryProduct] = useState(
    values.changesForEveryProduct
  )

  useEffect(() => {
    if (changesForEveryProduct) {
      setFieldValue('customerOrderNo', '')
      setFieldValue('changesForEveryProduct', changesForEveryProduct)
    }
    // eslint-disable-next-line
  }, [changesForEveryProduct])

  return (
    <>
      <div className="grid grid-cols-[3fr_1fr] gap-x-5 items-end">
        <SelectCompany name="company" changename="Müşteri Adı" />
        <Input label="Sipariş No" name="orderNo" autoComplete="off" disabled />
      </div>
      <div className="grid grid-cols-4 gap-x-5">
        <Input label="Marka Order No" name="brandOrderNo" autoComplete="off" />
        <Input label="Supplier No" name="supplierNo" autoComplete="off" />
        <div className="grid grid-cols-2 gap-x-5 items-center col-span-2">
          <Input
            onChange={(e) => {
              setFieldValue('customerOrderNo', e.target.value)
              setFieldValue('washingLabel[0].customerOrderNo', e.target.value)
              setFieldValue('warningLabel[0].customerOrderNo', e.target.value)
              setFieldValue('priceLabel[0].customerOrderNo', e.target.value)


            }}
            value={values.customerOrderNo}
            label="Müşteri Sipariş No"
            disabled={changesForEveryProduct}
            name="customerOrderNo"
            autoComplete="off"
          />
          <div className="flex items-center space-x-2">
            <Checkbox
              id="terms"
              name="terms"
              defaultChecked={changesForEveryProduct}
              onCheckedChange={(e) => setChangesForEveryProduct(e)}
            />
            <label
              htmlFor="terms"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Her üründe değişiyor mu?
            </label>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-x-5">
        <Input label="Sezon" name="season" autoComplete="off" />
        <SelectMadeIn name="country" />
        <ArticelInput name="articleNo" label="Artıkel" />
        <SelectBodyChart name="sizeGroup" />
      </div>
    </>
  )
}
