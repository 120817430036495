import * as React from 'react'

import { cn } from 'lib/utils.js'
import { Label } from './label'

const Input = React.forwardRef(({ className, type, label, hidden, ...props }, ref) => {
  return (
    <div
      className={`grid ${label ? 'grid-cols-[100px_1fr]' : ''} items-center ${hidden && 'hidden'}`}
    >
      <Label htmlFor={props.name} className="text-simsek">
        {label}
      </Label>
      <input
        type={type}
        className={cn(
          'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-black outline-none disabled:cursor-not-allowed ',
          className
        )}
        ref={ref}
        {...props}
      />
    </div>
  )
})
Input.displayName = 'Input'

export { Input }
