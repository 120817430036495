import { useQuery } from '@tanstack/react-query';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import SearchSelect2Custom from '../search-select2custom';

export function SelectCurrency({ name,idx }) {
  const { values, setFieldValue } = useFormikContext(); // setFieldValue'i doğru şekilde alıyoruz

  // Para birimlerinin sabit bir listesi
  const currencyOptions1 = [
    { label: 'EUR', value: 'EUR' },
    { label: 'AUD', value: 'AUD' },
    // Diğer para birimleri...
  ];
  const currencyOptions2 = [
    { label: 'EUR', value: 'EUR' },
    { label: 'CLP', value: 'CLP' },
    // Diğer para birimleri...
  ];
  const currencyOptions3 = [
    { label: 'USD', value: 'USD' },
    { label: 'BRL', value: 'BRL' },
    // Diğer para birimleri...
  ];
  const currencyOptions4 = [
    { label: 'GBP', value: 'GBP' },
    // Diğer para birimleri...
  ];

  // `name`'e bağlı olarak doğru currencyOptions setini seçmek için bir yöntem
  const getCurrencyOptions = () => {
    switch(name) {
      case 'currency1': return currencyOptions1;
      case 'currency2': return currencyOptions2;
      case 'currency3': return currencyOptions3;
      case 'currency4': return currencyOptions4;
      default: return []; // Varsayılan olarak boş bir array dön
    }
  };

  const getNewValue = () => {
    switch(name) {
      case 'currency1': return values.priceLabel[0].mix[0]?.type || '';
      case 'currency2': return values.priceLabel[0].mix[1]?.type || '';
      case 'currency3': return values.priceLabel[0].mix[2]?.type ||'';
      case 'currency4': return values.priceLabel[0].mix[3]?.type || '';
      default: return []; // Varsayılan olarak boş bir array dön
    }
  };

  const getTheField = () => {
    switch(name) {
      case 'currency1': return `priceLabel[${0}].mix[0].type`;
      case 'currency2': return `priceLabel[${0}].mix[1].type`;
      case 'currency3': return `priceLabel[${0}].mix[2].type`;
      case 'currency4': return `priceLabel[${0}].mix[3].type`;
      default: return []; // Varsayılan olarak boş bir array dön
    }
  };
  const currencyOptions = getCurrencyOptions(); // Seçilen para birimi seti
  const NewValue= getNewValue();
  const NewField= getTheField();

  /*useEffect(() => {
    // name alanına varsayılan bir değer atama
    // Sadece eğer form değeri henüz ayarlanmamışsa
    if (!values[name] && currencyOptions.length > 0) {
      setFieldValue(name, currencyOptions[0].value); // İlk değeri varsayılan olarak ayarla
    }
  }, [setFieldValue, name, values, currencyOptions]);
  */

  return (
    <SearchSelect2Custom
      label={name}
      options={currencyOptions}
      name={name}
      value={NewValue} // Formik'ten alınan mevcut değeri kullan
      onSelect={(value) => {
        setFieldValue(NewField, value); // Formik değerini onSelect ile güncelle
      }}
    />
  );
}
