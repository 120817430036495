import { useNavigate } from 'react-router-dom'
import { ChevronLeft } from 'lucide-react'
import { twMerge } from 'tailwind-merge'

export default function MainTitle({ children, back, className }) {
  const navigate = useNavigate()
  const cn = twMerge('text-2xl text-theme-blue', className)

  if (back)
    return (
      <div className="flex items-center gap-x-2">
        <div
          className="bg-white rounded-full p-2 cursor-pointer"
          onClick={() => (back.length > 0 ? navigate(back) : navigate(-1))}
        >
          <ChevronLeft className="text-theme-blue" />
        </div>
        <div className={cn}>{children}</div>
      </div>
    )

  return <div className="text-2xl text-theme-blue">{children}</div>
}
