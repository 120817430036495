import { LogOutModal } from 'components/log-out'
import Logo from 'components/logo'
import { Sidebar } from 'components/sidebar'
import { Outlet } from 'react-router-dom'

export default function DashboardLayout() {
  return (
    <div
      className="min-h-screen"
      style={{
        background: 'url(/background.jpg) no-repeat bottom left fixed',
        backgroundSize: 'cover'
      }}
    >
      <div className="h-20 bg-simsek px-[50px] grid grid-cols-3 items-center">
        <Logo />
        <div className="justify-self-center">
          <Sidebar />
        </div>
        <div className="justify-self-end">
          <LogOutModal />
        </div>
      </div>
      <div className="px-[200px] pt-10 pb-10">
        <Outlet />
      </div>
    </div>
  )
}
