import { FieldArray, useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'
import { RotateCcw } from 'lucide-react'

import { useOrderWarningSave, useOrderWarningUpdate } from 'store/server/useOrder'

import { Input } from 'components/form/input'
import { Input as NoFormInput } from 'components/ui/input'
import { Button } from 'components/ui/button'
import { Separator } from 'components/ui/separator'
import { SelectReferanceCode2 } from 'components/form/compobox/select-referance-code2'

import { Link } from 'react-router-dom'
import { X } from 'lucide-react'

export default function WashingInstructions({ currentTab }) {
  const { values, setFieldValue } = useFormikContext()
  const { mutate: saveOrder, isPending } = useOrderWarningSave()
  const { mutate: updateOrder, isPending: isPendingUpdate } = useOrderWarningUpdate()
  const { brand, orderNo, dept, subDept } = useParams()
  const brandId = brand.split('_')[0]

  const handleSaveOrder = () => {
    const department = `${brand.split('_')[1]} / ${dept.split('_')[1]} / ${subDept}`
    let submissionValues = { ...values };
  
    // washingLabel ve priceLabel özelliklerini kaldır
    delete values.brandId

    const path = `${brand}/${dept}/${subDept}`
    saveOrder({ ...submissionValues,  brand: brandId, department, path })
  }


  const handleUpdateOrder = () => {
    let submissionValues = { ...values };
  
    const filteredValues = values.washingLabel.map((label) => {
      const filteredFabric = label.fabric.map((fabricItem) => {
        const filteredMix = fabricItem.mix.filter((mix) => mix.percent > 0)
        return { ...fabricItem, mix: filteredMix }
      })

      return { ...label, fabric: filteredFabric }
    })
    delete values.brandId

    updateOrder({ id: orderNo, body: { ...submissionValues, washingLabel: filteredValues,brand: brandId } })
  }

  return (
    <FieldArray name="warningLabel">
      {({ push, remove }) => (
        <div>
          {values.warningLabel.map((_, idx) => (
            <div className="grid grid-cols-2 gap-5 pb-5 relative" key={idx}>
              <div className="col-span-2 grid grid-cols-2 gap-2 rounded border border-dashed p-2">
              
                    <NoFormInput
                      label="Müşteri Sipariş No"
                      value={values.warningLabel[0]?.customerOrderNo}
                      onChange={(e) => {
                        if (values.changesForEveryProduct) {
                          setFieldValue('warningLabel[0].customerOrderNo', e.target.value)
                        }
                      }}
                    />
                    <Input
                      label="Müşteri Sipariş Detayı"
                      name={`warningLabel[${idx}].customerOrderDetail`}
                      autoComplete="off"
                    />

                    <Input
                      label="Renk Kodu"
                      name={`warningLabel[${idx}].colorCode`}
                      maxLength="3"
                      autoComplete="off"
                    />
                     


                    <div className={`${idx > 0 ? 'hidden' : ''}`}>
                      <SelectReferanceCode2 name={`warningLabel[${idx}].referanceCode`} />
                    </div>
                    <Input
                    type="number"
                      label="Adet"
                      name={`warningLabel[${idx}].qauntity`}
                      autoComplete="off"
                    />

              </div>
 
            
                  <Separator className="col-span-2 h-[5px] bg-simsek" />
                  {idx > 0 && (
                    <Button
                      variant="destructive"
                      onClick={() => remove(idx)}
                      className="absolute -right-40 top-0"
                    >
                      <X className="w-4 h-4 text-white" />
                      Renk Sil
                    </Button>
                  )}
              
      
            </div>
          ))}
          <div className="flex items-center gap-x-5">
            {!orderNo && (
              <Button
                type="submit"
                variant="softgreen"
                onClick={handleSaveOrder}
                disabled={isPending}
                className="w-full"
              >
                {isPending && <RotateCcw className="animate-spin mr-2" size={16} />}
                Kaydet
              </Button>
            )}
            <Button
              type="button"
              onClick={orderNo ? handleUpdateOrder : handleSaveOrder}
              disabled={isPending || isPendingUpdate}
              className="w-full"
              variant={orderNo && 'softgreen'}
            >
              {(isPending || isPendingUpdate) && (
                <RotateCcw className="animate-spin mr-2" size={16} />
              )}
              {orderNo ? 'Güncelle' : "Artwork'e Git"}
            </Button>
            {orderNo && (
              <Link
                to={`/siparisler/artwork/${orderNo}?tab=${1}`}
                target="_blank"
                className="w-full"
              >
                <Button type="button" disabled={isPending} className="w-full">
                  {isPending && <RotateCcw className="animate-spin mr-2" size={16} />}
                  Artwork'e Git
                </Button>
              </Link>
            )}
            <Button
              type="button"
              variant="blue"
              onClick={() => {
                push({
                  ...values.warningLabel[0],
                  colorCode: '',
                  qauntity:'',
                  sizeDefectivePercent: '',
                })
              }}
              className="w-full"
            >
              Renk Ekle
            </Button>
          </div>
        </div>
      )}
    </FieldArray>
  )
}
