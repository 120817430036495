import FrImage from 'assets/fr.svg'
import { useRef } from 'react'
import MultiFabricLabelsYatay from '../MultiFabricLabelsYatay'
import LabelCard from '../LabelCard'

export default function YatayPage2({ data }) {
  const ref = useRef(null)
  const totalLabelCount = ref.current?.children.length + 2

  return (
    <div className="flex flex-wrap gap-3" ref={ref}>
      <MultiFabricLabelsYatay data={data} />
      <LabelCard type={totalLabelCount % 2 === 0 ? 'back' : 'front'} number={totalLabelCount}>
        <div className="flex items-center justify-center h-full">
          <img src={FrImage} className="w-[27mm] h-[12.8mm]" alt="fr logo" />
        </div>
      </LabelCard>
    </div>
  )
}
