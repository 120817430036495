import React, { useState, useEffect } from 'react'
import LabelCard from './LabelCard'
import { LeatherIcon } from 'assets/icons'
import { ReactComponent as IconCow } from '../../../../assets/trace.svg' // SVG'yi React bileşeni olarak içe aktarma
import { ReactComponent as IconCowWhite } from '../../../../assets/trace.svg' // SVG'yi React bileşeni olarak içe aktarma
import useOrderStore from 'store/client/orderStore'

export default function MultiFabricLabels({ data: { washingLabel } }) {
  const { referance } = useOrderStore()

  let currentNumber = 5 // Burada değişken olarak başlatıyoruz
  const data = washingLabel[0].fabric
  console.log(JSON.stringify(washingLabel))
  const leatherTypes = [
    "BUFFALO FURSKIN", "BUFFALO LEATHER", "CALF FURSKIN", "COLT LEATHER",
    "COW FURSKIN", "GOAT LEATHER", "HORSE LEATHER", "LAMB FURSKIN",
    "LAMBSKIN", "PIG FURSKIN", "PIG LEATHER", "RABBIT LEATHER",
    "SHEEP FURSKIN", "SHEEP LEATHER"
  ]
  const isBlackLabel = referance?.backgroundColor === 'B'

  const [isLeatherType, setIsLeatherType] = useState(false)

  useEffect(() => {
    const containsLeather = data.some(item =>
      item.mix.some(mix => leatherTypes.includes(mix.type))
    )
    if (containsLeather) {
      setIsLeatherType(true)
    }
  }, [data])

  const getType = (number) => (number % 2 === 0 ? 'back' : 'front')

  const renderLabelCards = (item) => {
    const cards = []
    let currentCardContents = []
    let currentCardLength = 0

    const addCard = () => {
      if (currentCardContents.length > 0) {
        cards.push(
          <LabelCard
            className="font-arialMs text-[5.7pt] leading-[5.7pt] px-[2.5mm]"
            number={currentNumber}
            type={getType(currentNumber)}
          >
            <div className="flex flex-col items-start justify-center h-full pt-1 -mt-[2px]">
              {currentCardContents.map((content, index) => {
                const [percent, text] = content.split('%')
                const isFabric = percent.length > 3
                const LeatherIcon = isBlackLabel ? IconCowWhite : IconCow // İkonu seç

                return (
                  <div
                    key={index}
                    className={`flex items-center gap-x-[2px] text-justify mt-[5px] ${
                      isFabric ? 'scale-y-[120%]' : 'scale-y-[120%]'
                    }`}
                    style={{
                      paddingTop: currentNumber === 8 && isFabric ?  '5px' : '0px', // currentNumber 8 olduğunda padding ekleniyor
                      transform: `scaleY(${currentNumber === 8 && isFabric ? 1.04 : 1.2})`
                  }}
                    
                  >
                    <div className="flex flex-col" style={{marginBottom:7}}>
                      <div className={`${percent.length <= 3 && 'w-[20px]'}`}>
                        <span >{isFabric ? percent : percent + '%'}</span>
                      </div>
                      {isLeatherType && (
        <LeatherIcon
          style={{
            width: '24px',
            height: '16px',
            flexShrink: 0, // Sıkışmasını engeller
            paddingLeft:5
          }}
          preserveAspectRatio="none" // SVG'nin orantılı davranışını devre dışı bırakır

        />
      )}                    </div>
                    <div>{text}</div>
                  </div>
                )
              })}
            </div>
          </LabelCard>
        )
        currentNumber++ // currentNumber'ı artırıyoruz
      }
      currentCardContents = []
      currentCardLength = 0
    }

    if (item.text?.length <= 350) {
      currentCardContents.push(item.text)
      currentCardLength += item.text.length
    }

    item.mix.forEach((mix) => {
      const mixText = `${mix.percent}% ${mix.text}`
      if (currentCardLength + mix.text.length <= 320) {
        currentCardContents.push(mixText)
        currentCardLength += mix.text.length
      } else {
        addCard()
        currentCardContents.push(mixText)
        currentCardLength = mix.text.length
      }
    })

    addCard()
    return cards
  }

  return { cards: data.map((item) => <>{renderLabelCards(item)}</>), lastNumber: currentNumber }
}