import { Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'

import { useOrderDetail } from 'store/server/useOrder'

import BottomSection from './BottomSection'
import TopSection from './TopSection'
import MainTitle from 'components/MainTitle'
import Spinner from 'components/spinner'
import Excel from './excel'
import CopyOrder from './copy-order'

export default function AddOrderFormDetail() {
  const { brand, dept, subDept, orderNo } = useParams()
  const { data: orderDetail, isFetching, isError } = useOrderDetail(orderNo)

  if (isFetching) return <Spinner />
  if (isError) return <div className="text-4xl font-light">Böyle bir sipariş bulunamadı...</div>
  return (
    <div>
      <div className="mb-5 flex items-center justify-between">
        <MainTitle back>Sipariş Ekle</MainTitle>
        <p className="font-semibold p-2 rounded-lg uppercase">
          {brand.split('_')[1]} / {dept.split('_')[1]} / {subDept}
        </p>
      </div>
      <div className="flex justify-end gap-x-2">
        <CopyOrder no={orderDetail.orderNo} />
        <Excel />
      </div>
      <Formik initialValues={orderDetail}>
        <Form className="space-y-5">
          <div className="rounded bg-white p-5 space-y-2 shadow-xl">
            <TopSection />
          </div>
          <BottomSection />
        </Form>
      </Formik>
    </div>
  )
}
