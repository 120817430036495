import { useQuery } from '@tanstack/react-query'
import requests from 'service/api'

export function useBrandList() {
  return useQuery({
    queryKey: ['/list', 'brand'],
    queryFn: async () => {
      const { data } = await requests.brand.list()
      return data
    },
    placeholderData: []
  })
}

export function useDepartmentList(id) {
  return useQuery({
    queryKey: ['/list', 'department', id],
    queryFn: async () => {
      const { data } = await requests.brand.listDepartment(id)
      return data
    },
    placeholderData: []
  })
}

export function useSubDepartmentList(id) {
  return useQuery({
    queryKey: ['/list', 'subDepartment', id],
    queryFn: async () => {
      const { data } = await requests.brand.listSubDepartment(id)
      return data
    },
    placeholderData: []
  })
}
