import { FieldArray, useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'
import { RotateCcw } from 'lucide-react'

import { useOrderPriceSave, useOrderPriceUpdate } from 'store/server/useOrder'

import { Input } from 'components/form/input'
import { Input as NoFormInput } from 'components/ui/input'
import { Button } from 'components/ui/button'
import { Separator } from 'components/ui/separator'
import { SelectReferanceCode3 } from 'components/form/compobox/select-referance-code3'
import { toast } from 'components/ui/use-toast'
import PriceSizes from '../PriceInstructions/PriceSize'
import PriceSection from '../PriceInstructions/PriceSection'
import { Link } from 'react-router-dom'
import { X } from 'lucide-react'

export default function PriceInstructions({ currentTab }) {
  const { values, setFieldValue } = useFormikContext()
  const { mutate: saveOrder, isPending } = useOrderPriceSave()
  const { mutate: updateOrder, isPending: isPendingUpdate } = useOrderPriceUpdate()
  const { brand, orderNo, dept, subDept } = useParams()
  const brandId = brand.split('_')[0]
  const handleSaveOrUpdateOrder = () => {
    // Hata mesajlarını saklamak için bir dizi
    const errors = [];
  
    // currency1 ve currency2 için boş olup olmadığını kontrol et
    values.priceLabel.forEach((item, index) => {
      if (!item.mix[0]?.currency) {
        errors.push(`Price Label ${index + 1}: Currency 1 alanı boş olamaz.`);
      }
      if (!item.mix[1]?.currency) {
        errors.push(`Price Label ${index + 1}: Currency 2 alanı boş olamaz.`);
      }
    });
  
    // Hata varsa, işlemi durdur ve hataları göster
    if (errors.length > 0) {
      // Hataları gösterme şeklinizi buraya yazın. Örneğin:
      alert(errors.join('\n'));
      return; // İşlemi durdur
    }
  }
  const handleSaveOrder = () => {
    const errors = [];
    values.priceLabel.forEach((item, index) => {
      if (!item.mix[0]?.currency) {
        errors.push(`Price Label ${index + 1}: Currency 1 alanı boş olamaz.`);
      }
      if (!item.mix[1]?.currency) {
        errors.push(`Price Label ${index + 1}: Currency 2 alanı boş olamaz.`);
      }
    });
    if (!values.priceLabel[0]?.colorCode) {
      toast.error("Renk Kodu Girilmek Zorunda")
      return
    }
    if (errors.length > 0) {
      // Hataları gösterme şeklinizi buraya yazın. Örneğin:
      toast.error("Renk Kodu Girilmek zorunda")
      return; // İşlemi durdur
    }
    const department = `${brand.split('_')[1]} / ${dept.split('_')[1]} / ${subDept}`
    const filteredValues = values.washingLabel.map((label) => {
      const filteredFabric = label.fabric.map((fabricItem) => {
        const filteredMix = fabricItem.mix.filter((mix) => mix.percent > 0)
        return { ...fabricItem, mix: filteredMix }
      })

      return { ...label, fabric: filteredFabric }
    })
    const path = `${brand}/${dept}/${subDept}`
    saveOrder({ ...values, washingLabel: filteredValues, brand: brandId, department, path })
  }

  const hasDuplicateFabricTypes = () => {
    return values.washingLabel.some((label) => {
      const fabricTypes = label.fabric.flatMap((fabric) => fabric.mix.map((mix) => mix.type))
      const uniqueFabricTypes = new Set(fabricTypes)
      return uniqueFabricTypes.size !== fabricTypes.length
    })
  }

  const handleUpdateOrder = () => {
    const errors = [];
    values.priceLabel.forEach((item, index) => {
      if (!item.mix[0]?.currency) {
        errors.push(`Price Label ${index + 1}: Currency 1 alanı boş olamaz.`);
      }
      if (!item.mix[1]?.currency) {
        errors.push(`Price Label ${index + 1}: Currency 2 alanı boş olamaz.`);
      }
    });

    if (errors.length > 0) {
      // Hataları gösterme şeklinizi buraya yazın. Örneğin:
      return; // İşlemi durdur
    }
    console.log("color codee",values.priceLabel[0]?.colorCode)
    if (!values.priceLabel[0]?.colorCode) {
      toast.error("Renk Kodu Girilmek Zorunda")
      return
    }
    // Tekrar eden kumaş türü yoksa, güncelleme işlemini gerçekleştir
    const filteredValues = values.washingLabel.map((label) => {
      const filteredFabric = label.fabric.map((fabricItem) => {
        const filteredMix = fabricItem.mix.filter((mix) => mix.percent > 0)
        return { ...fabricItem, mix: filteredMix }
      })

      return { ...label, fabric: filteredFabric }
    })
    delete values.brandId
    updateOrder({ id: orderNo, body: { ...values, washingLabel: filteredValues, brand: brandId } })
  }

  return (
    <FieldArray name="priceLabel">
      {({ push, remove }) => (
        <div>
          {values.priceLabel.map((_, idx) => (
            <div className="grid grid-cols-2 gap-5 pb-5 relative" key={idx}>
              <div className="col-span-2 grid grid-cols-2 gap-2 rounded border border-dashed p-2">
      
                    <NoFormInput
                      label="Müşteri Sipariş No"
                      value={values.priceLabel[0]?.customerOrderNo}
                      onChange={(e) => {

                        if (values.changesForEveryProduct) {
                          setFieldValue('priceLabel.[0].customerOrderNo', e.target.value)
                        }
                      }}
                    />

                    <Input
                      label="Müşteri Sipariş Detayı"
                      name={`priceLabel[${idx}].customerOrderDetail`}
                      autoComplete="off"
                    />

                    <Input
                      label="Renk Kodu"
                      name={`priceLabel[${idx}].colorCode`}
                      maxLength="3"
                      autoComplete="off"
                    />

                    <div className={`${idx > 0 ? 'hidden' : ''}`}>
                      <SelectReferanceCode3 name={`priceLabel[${idx}].referanceCode`} />
                    </div>
  

              </div>
              <div className="col-span-2 grid grid-cols-2 gap-x-5">
            <PriceSizes idx={idx} />
            
                <PriceSection idx={idx}/>
                <Separator className="col-span-2 h-[5px] bg-simsek" />
                  {idx > 0 && (
                    <Button
                      variant="destructive"
                      onClick={() => remove(idx)}
                      className="absolute -right-40 top-0"
                    >
                      <X className="w-4 h-4 text-white" />
                      Renk Sil
                    </Button>
                  )}
         
              </div>
  

            </div>
          ))}
          <div className="flex items-center gap-x-5">
            {!orderNo && (
              <Button
                type="submit"
                variant="softgreen"
                onClick={handleSaveOrder}
                disabled={isPending}
                className="w-full"
              >
                {isPending && <RotateCcw className="animate-spin mr-2" size={16} />}
                Kaydet
              </Button>
            )}
            <Button
              type="button"
              onClick={orderNo ? handleUpdateOrder : handleSaveOrder}
              disabled={isPending || isPendingUpdate}
              className="w-full"
              variant={orderNo && 'softgreen'}
            >
              {(isPending || isPendingUpdate) && (
                <RotateCcw className="animate-spin mr-2" size={16} />
              )}
              {orderNo ? 'Güncelle' : "Artwork'e Git"}
            </Button>
            {orderNo && (
              <Link
                to={`/siparisler/artwork/${orderNo}?tab=${2}`}
                target="_blank"
                className="w-full"
              >
                <Button type="button" disabled={isPending} className="w-full">
                  {isPending && <RotateCcw className="animate-spin mr-2" size={16} />}
                  Artwork'e Git
                </Button>
              </Link>
            )}
            <Button
              type="button"
              variant="blue"
              onClick={() => {
                push({
                  ...values.priceLabel[0],
                  colorCode: '',
                  sizeDefectivePercent: '',
                  sizes: values.priceLabel[0].sizes.map((size) => ({
                    ...size,
                    amount: '',
                    amountWithDefect: ''
                  }))
                })
              }}
              className="w-full"
            >
              Renk Ekle
            </Button>
          </div>
        </div>
      )}
    </FieldArray>
  )
}
