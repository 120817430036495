export const formattedPhoneNumber = (phoneNumber) => {
  if (!phoneNumber.startsWith('0')) {
    phoneNumber = '0' + phoneNumber
  }
  return (
    phoneNumber.substring(0, 1) +
    ' ' +
    phoneNumber.substring(1, 4) +
    ' ' +
    phoneNumber.substring(4, 7) +
    ' ' +
    phoneNumber.substring(7, 9) +
    ' ' +
    phoneNumber.substring(9)
  )
}

export const formattedPrice = (value) => {
  return value?.toLocaleString('en-EN', { currency: 'USD', style: 'currency' })
}

export const formatEmpty = (value) => {
  return value?.split(' ').filter(Boolean).join(' ')
}

export const formatCharacter = (data, chunkSize = 300) => {
  const combinedTranslations = data.reduce((acc, item) => {
    const translations = item.translate
      .filter((translation, index, self) => {
        return (
          translation.translation !== '' &&
          self.findIndex((t) => t.translation === translation.translation) === index
        )
      })
      .map((translation) => translation.translation)
    return [...acc, ...translations, '\n']
  }, [])

  const newArray = []
  let totalLength = 0
  let currentIndex = 0

  for (let i = 0; i < combinedTranslations.length; i++) {
    totalLength += combinedTranslations[i].length

    if (totalLength + combinedTranslations[i].length <= chunkSize) {
      if (!newArray[currentIndex]) {
        newArray[currentIndex] = []
      }
      newArray[currentIndex].push(combinedTranslations[i])
    } else {
      currentIndex++
      totalLength = 0
      i--
    }
  }

  const sedat = newArray.map((dizi) => dizi.join(' - '))

  const demir = sedat.map((item) => {
    if (item.startsWith('- ')) return item.substring(2)
    if (item.charAt(item.length - 3) === '-') return item.substring(0, item.length - 3)
    return item
  })

  return demir
}
