import { useMutation, useQuery,useInfiniteQuery } from '@tanstack/react-query'
import { toast } from 'components/ui/use-toast'
import queryClient from 'config/tanstackQuery'
import { useNavigate } from 'react-router-dom'
import requests from 'service/api'



export function useOrderList({ status, search = '' }) {
  return useInfiniteQuery({
    queryKey: ['getOrders', status, search],
    queryFn: async ({ pageParam = 1 }) => {
      console.log("Fetching page:", pageParam);
      const response = await requests.order.list(status, pageParam, search);
      console.log("API Response:", response);

      return response.data;
    },
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.length < 30 ? undefined : allPages.length + 1;
    },
  });
}
export function useOrderDetail(orderNo) {
  return useQuery({
    queryKey: ['/get', orderNo],
    queryFn: async () => {
      const response = await requests.order.get(orderNo)
      return response.data
    },
    placeholderData: {}
  })
}

export function useOrderSave() {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async (body) => await requests.order.create(body),
    onSuccess: ({ data }) => {
      if (data) {
        navigate(`/siparisler/ekle/${data.path}/${data.id}`)
        setTimeout(() => {
          window.location.reload()
        }, 500)
        toast.success('Sipariş başarıyla kaydedildi')
      }
    },
    onError: (error) => {
      toast.error(error.response.data.message)
    }
  })
}

export function useOrderWarningSave() {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async (body) => await requests.order.createwarning(body),
    onSuccess: ({ data }) => {
      if (data) {
        navigate(`/siparisler/ekle/${data.path}/${data.id}`)
        setTimeout(() => {
          window.location.reload()
        }, 500)
        toast.success('Sipariş başarıyla kaydedildi')
      }
    },
    onError: (error) => {
      toast.error(error.response.data.message)
    }
  })
}

export function useOrderPriceSave() {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async (body) => await requests.order.createprice(body),
    onSuccess: ({ data }) => {
      if (data) {
        navigate(`/siparisler/ekle/${data.path}/${data.id}`)
        setTimeout(() => {
          window.location.reload()
        }, 500)
        toast.success('Sipariş başarıyla kaydedildi')
      }
    },
    onError: (error) => {
      toast.error(error.response.data.message)
    }
  })
}

export function useOrderCopy() {
  return useMutation({
    mutationFn: async (id) => await requests.order.copyOrder(id),
    onSuccess: ({ data }) => {
      if (data) {
        const url = `/siparisler/ekle/${data.path}/${data.id}`
        window.open(url, '_blank')
        toast.success('Sipariş başarıyla kopyalandı.')
      }
    },
    onError: (error) => {
      toast.error(error.response.data.message)
    }
  })
}

export function useOrderUpdate() {
  return useMutation({
    mutationFn: async ({ id, body }) => await requests.order.update(id, body),
    onSuccess: () => {
      toast.success('Güncelleme başarılı.')
    },
    onError: (error) => {
      toast.error(error.response.data.message)
    }
  })
}

export function useOrderWarningUpdate() {
  return useMutation({
    mutationFn: async ({ id, body }) => await requests.order.updatewarning(id, body),
    onSuccess: () => {
      toast.success('Güncelleme başarılı.')
    },
    onError: (error) => {
      toast.error(error.response.data.message)
    }
  })
}
export function useOrderPriceUpdate() {
  return useMutation({
    mutationFn: async ({ id, body }) => await requests.order.updateprice(id, body),
    onSuccess: () => {
      toast.success('Güncelleme başarılı.')
    },
    onError: (error) => {
      toast.error(error.response.data.message)
    }
  })
}
export function useOrderUpdateStatus() {
  return useMutation({
    mutationFn: async ({ id, body }) => await requests.order.updateStatus(id, body),
    onSuccess: () => {
      queryClient.invalidateQueries(['/GetOrders'])
      toast.success('Güncelleme başarılı.')
    },
    onError: (error) => {
      toast.error(error.response.data.message)
    }
  })
}

export function useArtworkOrder(orderNo, tab) {
  return useQuery({
    queryKey: ['/getArtworkOrder', orderNo, tab],
    queryFn: async () => {
      const response = await requests.order.getArtworkOrder(orderNo, tab);
      return response.data;
    },
    placeholderData: []
  });
}
export function useCreatePDF() {
  return useMutation({
    mutationFn: async (pdfData) => {
      const response = await requests.pdf.createPDF(pdfData);
      const url = URL.createObjectURL(response.data);
      return url;
    }
  });
}