
import { useFormikContext } from 'formik'; // useFormikContext'i import edin
import MainTitle from 'components/MainTitle'
import { Input } from 'components/form/input';
import requests from 'service/api';
import React from 'react';
import { toast } from 'components/ui/use-toast';
import { SelectCompany2 } from 'components/form/compobox/select-company2';

export default function AllUser({ idx }) {

  const updateUser = async (idx) => {
    const user = values.UserList[idx];
    try {
      const data = {
        id: user.id,
        mail: user.mail,
        username: user.username,
        isActive: user.isActive,
        tierType: user.tierType,
        password: user.seeingpassword, // Backend tarafında tekrar hash'lenecek
        companyName:user.companyName, // Backend tarafında tekrar hash'lenecek
        companyId:user.companyId, // Backend tarafında tekrar hash'lenecek
      };
      const response = await requests.auth.updateUser(data); // Kullanıcı güncelleme isteği
      toast.success('Güncelleme Başarılı');
      // Başarılı güncelleme mesajı veya işlemi burada yapabilirsiniz.
    } catch (error) {
      toast.error('Güncelleme Başarısız');
      // Hata mesajını burada gösterebilirsiniz.
    }
  };
  const deleteUser = async (idx) => {
    const isConfirmed = window.confirm("Bu kullanıcıyı silmek istediğinize emin misiniz?");
    if (!isConfirmed) {
      return; // Eğer kullanıcı iptal ederse, işlemi durdur
    }
    const user = values.UserList[idx];
    try {
      let deleteid=user.id


      const response = await requests.auth.deleteUser(deleteid); // Kullanıcı güncelleme isteği
      const updatedUserList = values.UserList.filter((_, userIndex) => userIndex !== idx);
      setFieldValue('UserList', updatedUserList);
      toast.success('Silme Başarılı');
      // Başarılı güncelleme mesajı veya işlemi burada yapabilirsiniz.
    } catch (error) {
      toast.error('Silme Başarısız');
      // Hata mesajını burada gösterebilirsiniz.
    }
  };


  const { values, setFieldValue } = useFormikContext();

  return (
    <div class="grid grid-cols-[2fr_2fr_2fr_2fr_1fr_1fr_1fr_1fr_7px] gap-x-5 bg-gray-100 rounded-lg p-5 items-center border-2 border-gray-400 border-opacity-0 hover:border-opacity-100 border-dashed transition-all duration-500 ease-in-out">
    <div className="my-2 px-2 w-full overflow-hidden">
    <SelectCompany2 
    name={`UserList[${idx}].companyId`} 
    labelName={`UserList[${idx}].companyName`} 
    defaultChoice={values.UserList[idx]?.companyId}
  /> 
  </div>
      <div className="my-2 px-2 w-full overflow-hidden">
        <Input
          name={`UserList[${idx}].mail`}
          placeholder="E-posta"
          autoComplete="off"
          value={values.UserList[idx]?.mail || ''}
          onChange={(e) => setFieldValue(`UserList[${idx}].mail`, e.target.value)}
        />
      </div>
      
      <div className="my-2 px-2 w-full overflow-hidden">
        <Input
          name={`UserList[${idx}].seeingpassword`}
          placeholder="Şifre"
          type="text"
          autoComplete="off"
          value={values.UserList[idx]?.seeingpassword || ''}
          onChange={(e) => setFieldValue(`UserList[${idx}].seeingpassword`, e.target.value)}
        />
      </div>

      <div className="my-2 px-2 w-full overflow-hidden">
        <Input
          name={`UserList[${idx}].username`}
          placeholder="Kullanıcı Adı"
          autoComplete="off"
          value={values.UserList[idx]?.username || ''}
          onChange={(e) => setFieldValue(`UserList[${idx}].username`, e.target.value)}
        />
      </div>

      <div className="my-2 px-2 w-full overflow-hidden">
        <div className="flex justify-between">
          <select
            className="flex-grow rounded-md border border-gray-300 h-10 pl-3 pr-8 text-base placeholder-white focus:shadow-outline" style={{backgroundColor:"white"}}
            name={`UserList[${idx}].tierType`}
            onChange={(e) => setFieldValue(`UserList[${idx}].tierType`, e.target.value)}
            value={values.UserList[idx]?.tierType || ''}
          >
            <option value="USER">USER</option>
            <option value="ADMIN">ADMIN</option>
          </select>
        </div>
      </div>

      <div className="my-2 px-2 w-full overflow-hidden">
        <div className="flex justify-between">
          <select
            className="flex-grow rounded-md border border-gray-300 h-10 pl-3 pr-8 text-base placeholder-white focus:shadow-outline" style={{backgroundColor:"white"}}
            name={`UserList[${idx}].isActive`}
            onChange={(e) => setFieldValue(`UserList[${idx}].isActive`, e.target.value)}
            value={values.UserList[idx]?.isActive.toString() || ''}
          >
            <option value="true">TRUE</option>
            <option value="false">FALSE</option>
          </select>
        </div>
      </div>

      <div className="my-2 px-2 w-full overflow-hidden">
      <button
          type="button"
          className="w-full bg-green-500 text-white py-2 rounded hover:bg-green-600"
          onClick={() => updateUser(idx)}
        >
          Güncelle
        </button>
      </div>

      <div className="my-2 px-2 w-full overflow-hidden">
        <button
          type="button"
          className="w-full bg-red-500 text-white py-2 rounded hover:bg-red-600"
          onClick={() => deleteUser(idx)}

        >
          Sil
        </button>
      </div>
    </div>
  );
}
