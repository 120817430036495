import Barcode from 'react-barcode'

import LabelCard from '../LabelCard'
import { GrupoLogo } from 'assets/icons'
import useOrderStore from 'store/client/orderStore'

import React, { useRef, useEffect } from 'react';

export default function Page1({ sizeData, selectColor = 0, barcodeValue, data }) {
  const { referance } = useOrderStore()
  const isBlack = referance?.backgroundColor === 'B'
  const barcodeRef = useRef(null);

  useEffect(() => {
    const svgElement = barcodeRef.current.querySelector('svg');
    const textElement = svgElement?.querySelector('text');
    if (textElement) {
      textElement.style.fill = isBlack ? 'white' : ''; // isBlack true ise siyah, değilse kırmızı yap
    }
  }, [barcodeValue, isBlack]);  // barcodeValue veya isBlack değiştiğinde bu kod bloğunu çalıştır

  return (
    <div ref={barcodeRef} className="flex flex-wrap gap-3">
      <LabelCard type="front" number={1}>
        <div className="h-full pl-[4.5mm] pt-[9mm] pr-[5.5mm]">
        {isBlack && (
        <div style={{ backgroundColor: "white", height: 19, width: 161, position: "absolute", top: 35}}></div>
      )}
          <div className="flex">
            {isBlack && <div className="h-[19px] w-3 mt-[1px]" />}
            <div className="inline-flex flex-col tracking-[.10rem]">
              <Barcode
                value={barcodeValue.length === 13 ? barcodeValue : '0390547180002'}
                format="ITF14"
                width={1.009}
                height={20}
                marginLeft={1}
                marginRight={1}
                marginTop={1}
                marginBottom={1}
                background="transparent"
                lineColor={isBlack ? '#000000' : '#000000'}
                fontSize={9}
                font="Arial"
              />
            </div>
            {isBlack && <div className="h-[19px] w-3 mt-[1px]" />}
          </div>
          <div className="mt-[3.5mm] space-y-1">
            <div className="flex w-full justify-between">
              <p className="font-arialMs text-[6pt] font-normal">{`${data.articleNo}/${data.washingLabel[selectColor].colorCode}/${sizeData.name}`}</p>
              <p className="font-arialMs text-[7pt]">{sizeData.cn}</p>
            </div>
            <div className="flex w-full justify-between">
              <p className="font-arialNarrow text-[7pt]">RUC 80089348-4</p>
              <p className="font-arialNarrow text-[7pt]">RN 77302</p>
            </div>
            <div className="flex justify-center pt-[1mm]">
              <div className="w-[22mm] ml-4">
                <GrupoLogo />
              </div>
            </div>
          </div>
        </div>
      </LabelCard>
      <LabelCard type="back" number={2}>
        <div className="flex items-center h-full">
          <div className="scale-y-[158.7%] scale-x-[94%] leading-[4.5pt] pl-[2.5mm]">
            <p>INDITEX,S.A. AV. DIPUTACION ED.INDITEX ARTEIXO</p>
            <p>A CORUÑA (SPAIN) C.I.F. A-15075062</p>
            <p>TEXMODA S.A.S SIC 900.123.408 TAC</p>
            <p>
              ARGENTINA S.A. CUIT:30-69314836-3 V-
              {data?.country?.code}-21
            </p>
            <p>AZKA LOGISTICS LLC, TEXTILES ZANZIBAR S.A.</p>
            <p>RIF:N°J30536946-1 REG.NO:0000051 TI-02</p>
            <p>MACROMODA S.A. RUC 1792159105001 ZARA</p>
            <p>BRASIL LTDA. CNPJ:02952485000491 ZARA CANADA</p>
            <p>INC.CA 36685 아이티엑스코리아 자라코리아 서울시</p>
            <p>강남구 삼성동 E 20080-479-0880</p>
            <p>MA-MAROC: Sté AZZURRO IF: 1641920</p>
            <p>(株)ITXジャパン 03-6415-8061</p>
            <p className="text-[4.5pt]">
              IMPORTADO POR ZARA MEXICO, S.A. DE C.V. PONIENTE 146 NO. 730 COLONIA INDUSTRIAL
              VALLEJO ALCALDIA AZCAPOTZALCO, C.P. 02300, CIUDAD DE MEXICO, RFC:ZME920824KM3
            </p>
          </div>
        </div>
      </LabelCard>
    </div>
  )
}
