import { useCompany } from 'store/server/useCompany'

import SearchSelect from '../search-select'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

export function SelectCompany({ name,labelName,changename }) {
  const { values } = useFormikContext()
  const { data, isFetched } = useCompany()

  const [selected, setSelected] = useState(null)

  const options = data.map(({ name, id }) => ({
    value: id,
    label: name
  }))

  useEffect(() => {
    if (values.company && isFetched) {
      const select = options.find(({ value }) => value === values.company)
      setSelected(select)
    }
    // eslint-disable-next-line
  }, [values.company, isFetched])

  return (
    <SearchSelect
      label={changename}
      name={name}
      labelName={labelName}
      options={options}
      value={selected}
      onChange={(e) => setSelected(e)}
    />
  )
}
