import create from 'zustand';

const useSearchStore = create((set) => ({
  searchStore: '',

  // Arama değerini güncellemek için fonksiyon
  setSearch: (searchValue) => set({ searchStore: searchValue }),
}));

export default useSearchStore;
