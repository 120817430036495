import { Select } from 'antd';
import { useFormikContext } from 'formik';
import { CaretSortIcon } from '@radix-ui/react-icons';
import { twMerge } from 'tailwind-merge';

export default function SearchSelect2Custom({ options, name, className, ...props }) {
  const { setFieldValue } = useFormikContext();
  
  // Use `twMerge` to combine Tailwind classes, ensuring the Select component takes full width
  const cn = twMerge(`w-full items-center`, className);

  return (
    <div className={cn}>
      <Select
        id={name}
        showSearch
        allowClear
        onClear={() => setFieldValue(name, '')}
        suffixIcon={<CaretSortIcon />}
        className="w-full h-10" // Ensure full width and set height
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={options}
        onSelect={(value) => {
          setFieldValue(name, value);
        }}
        {...props}
      />
    </div>
  );
}
