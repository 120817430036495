import React from 'react'
import LabelCard from '../LabelCard'

export default function MultiFabricLabels({ data: { washingLabel } }) {
  const data = washingLabel[0].fabric
  let currentNumber = 4

  const getType = (number) => (number % 2 === 1 ? 'front' : 'back')

  const renderLabelCards = (item) => {
    const cards = []
    let currentCardContents = []
    let currentCardLength = 0
    const addCard = () => {
      if (currentCardContents.length > 0) {
        cards.push(
          <LabelCard
            className="font-arialMs text-[5.7pt] leading-[5.7pt] px-[2.5mm]"
            number={currentNumber}
            type={getType(currentNumber)}
          >
            <div className="flex flex-col items-center justify-center h-full pt-1 -mt-[4px]">
              {currentCardContents.map((content, index) => {
                const [percent, text] = content.split('%')
                const isFabric = percent.length > 3
                return (
                  <div
                    key={index}
                    className={`flex items-center gap-x-[2px] text-justify mt-[10px] ${
                      isFabric ? 'scale-y-[120%]' : 'scale-y-[120%]'
                    }`}
                  >
                    <div className={`${!percent.length > 3 && 'w-[42px]'}`}>
                      {isFabric ? percent : percent + '%'}
                    </div>
                    <div>{text}</div>
                  </div>
                )
              })}
            </div>
          </LabelCard>
        )
        currentNumber++
      }
      currentCardContents = []
      currentCardLength = 0
    }

    if (item.text?.length <= 350) {
      currentCardContents.push(item.text)
      currentCardLength += item.text.length
    }

    item.mix.forEach((mix) => {
      const mixText = `${mix.percent}% ${mix.text}`
      if (currentCardLength + mix.text.length <= 550) {
        currentCardContents.push(mixText)
        currentCardLength += mix.text.length
      } else {
        addCard()
        currentCardContents.push(mixText)
        currentCardLength = mix.text.length
      }
    })

    addCard()

    return cards
  }

  return { cards: data.map((item) => <>{renderLabelCards(item)}</>), lastNumber: currentNumber }
}
