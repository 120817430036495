import { useTotalCount } from 'store/server/useDashboard'

import TotalCard from './TotalCard'
import MainTitle from 'components/MainTitle'
import Spinner from 'components/spinner'

export default function Home() {
  const { data, isFetching } = useTotalCount()

  if (isFetching) return <Spinner />
  return (
    <div>
      <MainTitle>Ana Sayfa</MainTitle>
      <div className="grid grid-cols-3 gap-x-5 mt-5">
        <TotalCard title="Toplam Sipariş Sayısı" count={data.orderCount} />
        <TotalCard title="Toplam Marka Sayısı" count={data.brandCount} />
        <TotalCard title="Toplam Firma Sayısı" count={data.companyCount} />
      </div>
    </div>
  )
}
