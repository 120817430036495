import { CalendarIcon } from '@radix-ui/react-icons'
import { format } from 'date-fns'

import { cn } from 'lib/utils'
import { Button } from 'components/ui/button'
import { Calendar } from 'components/ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { Label } from './ui/label'

export default function DatePicker({ date, setDate, labelText, className }) {
  return (
    <div className="flex flex-col">
      {labelText && <Label className="mb-2 font-semibold">{labelText}</Label>}
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={'outline'}
            className={cn(
              `w-[240px] ${!date && 'text-muted-foreground'} justify-start text-left font-normal`,
              className
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date ? format(date, 'PPP') : <span>Pick a date</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar mode="single" selected={date} onSelect={setDate} initialFocus />
        </PopoverContent>
      </Popover>
    </div>
  )
}
