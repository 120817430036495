export default function A4Page({ children, type = 'dikey', ...props }) {
  // Yatay tip için kenar boşluklarını sıfırlıyoruz
  const paddingClasses = type === 'dikey' ? 'pl-[20mm] pt-[3mm]' : 'pt-[3mm]';

  return (
    <div
      style={{
        height: type === 'dikey' ? '297mm' : '210mm',
        width: type === 'dikey' ? '210mm' : '297mm',
      }}
      className={`bg-white ${paddingClasses}`}
      {...props}
    >
        {type === 'dikey' ? (
          <div className="space-y-5">

          <div className="flex flex-wrap gap-3">{children}</div>
          </div>

        ) : (
          // Yatay düzen için flex-wrap özelliği devam ettiriliyor
          <div>
          <div>
            {children}
          </div>
          </div>
        )}
    </div>
  );
}
