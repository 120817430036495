import * as Yup from 'yup'

export const createOrderSchema = Yup.object({
  washingLabel: Yup.array().of(
    Yup.object().shape({
      colorCode: Yup.string()
        .required('Renk kodu boş bırakılamaz')
        .max(3, 'Renk kodu 3 karakter olmalıdır')
        .min(3, 'Renk kodu 3 karakter olmalıdır')
    })
  )
})
