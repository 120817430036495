import { create } from 'zustand'

const useTemNewStore = create((set) => ({
    specialValue: 0, // Yeni eklenen değer
    anotherValue: 0, // İlk değer
    tabvalue: '', // İlk değer


    setSpecialValue: (value) => set({ specialValue: value }), // Bu değeri güncelleyecek fonksiyon
    setAnotherValue: (value) => set({ anotherValue: value }),
    settabvalue: (value) => set({ tabvalue: value }),


}))

export default useTemNewStore
