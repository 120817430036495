import React from 'react'
import A4Page from '../A4Page'
import TopDetail from '../TopDetail'
import SectionOne from './SectionOne'
import { ReactComponent as WarningSVG } from '../../../../../assets/warningwhite.svg';
import { ReactComponent as WarningBlackSVG } from '../../../../../assets/warningblack.svg';
import useOrderStore from 'store/client/orderStore'
import { WidthIcon } from '@radix-ui/react-icons';

const Warning_55_120 = ({ data, selectColor,tab }, ref) => {
  const { referance } = useOrderStore()
  const isBlack = referance?.backgroundColor === 'B'
  return (
    <div className="flex flex-col items-center" ref={ref}>
      <A4Page type="dikey">
        <div className="space-y-25">
          <TopDetail values={data} selectColor={selectColor} tab={tab} />
          <div style={{width:"115.85mm",marginTop:15}}>
            {isBlack ? <WarningBlackSVG /> : <WarningSVG />}
          </div>
        </div>
      </A4Page>
    </div>
  )
}

export default React.forwardRef(Warning_55_120)
