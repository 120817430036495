import * as React from 'react'

import { cn } from 'lib/utils.js'
import { useField, ErrorMessage } from 'formik'
import { Label } from 'components/ui/label'

const Input = React.forwardRef(({ className, type, label, value, ...props }, ref) => {
  const [field, , helpers] = useField(props)

  React.useEffect(() => {
    if (value) {
      helpers.setValue(value)
    }
    // eslint-disable-next-line
  }, [value])

  return (
    <div className={`grid ${label ? 'grid-cols-[100px_1fr]' : ''} items-center`}>
      <Label htmlFor={props.name} className="text-simsek">
        {label}
      </Label>
      <input
        id={props.name}
        type={type}
        className={cn(
          'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-black outline-none disabled:cursor-not-allowed',
          className
        )}
        ref={ref}
        {...field}
        {...props}
      />
      <span></span>
      <ErrorMessage
        component="small"
        name={field?.name || 'unnamed'}
        className="text-red-500 ml-2"
      />
    </div>
  )
})
Input.displayName = 'Input'

export { Input }
