import React, { createContext, useContext, useRef,useState } from "react";

// QR kodlarına özel bir context oluşturuyoruz
const QRRefContext = createContext();

export const QRRefProvider = ({ children }) => {
  const refMap = useRef({}); // QR kodlarına ait referansları saklamak için bir harita
  const [svgList, setSvgList] = useState([]);

  const getRef = (id) => {
    // Her zaman yeni bir ref oluştur
    console.log(`Creating a fresh ref for ID: ${id}`);
    return React.createRef();
  };
  const addSvg = (svg) => {
    setSvgList((prev) => [...prev, svg]); // Yeni SVG'yi listeye ekle
  };

  return (
    <QRRefContext.Provider value={{ getRef,addSvg,svgList }}>
      {children}
    </QRRefContext.Provider>
  );
};

export const useQRRef = () => useContext(QRRefContext);
