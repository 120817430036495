import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'

import queryClient from 'config/tanstackQuery'

import { Input } from 'components/form/input'
import useTemNewStore from 'store/client/temNewStore'
export default function Sizes({ idx }) {
  const { orderNo } = useParams()
  const { values, setFieldValue } = useFormikContext()

  const cacheData = queryClient.getQueryData(['/list', 'sizeGroup'])
  const { specialValue } = useTemNewStore();
  useEffect(() => {
    // Bu fonksiyon, cacheData ve sizeGroup'a bağlı olarak size alanlarını günceller.
  console.log("burada")
    const updateSizes = () => {
      if (cacheData) {
        const findSizeData = cacheData.find((item) => item.id === values.sizeGroup);
        const isCocuk = findSizeData?.name?.includes('ÇOCUK');
        const isBebek = findSizeData?.name?.includes('BEBEK');

        const newSizes = findSizeData?.sizes.map((item, idx2) => ({
          name: isCocuk || isBebek ? item.size.split(' ')[0] : String(item.europe),
          amount: '',
          amountWithDefect: '',
          cn: item.cn,
          sCode: item.sCode || String(item.europe),
        })) || [];
        
        setFieldValue(`washingLabel[${idx}].sizes`, newSizes);
        setFieldValue(`washingLabel[${idx}].sizeDefectivePercent`, '');
      }
    };
    const updateSizes2 = () => {
      if (cacheData) {
        const findSizeData = cacheData.find((item) => item.id === values.sizeGroup);
        const isCocuk = findSizeData?.name?.includes('ÇOCUK');
        const isBebek = findSizeData?.name?.includes('BEBEK');
    
        const newSizes = findSizeData?.sizes.map((item, idx2) => ({
          name: isCocuk || isBebek ? item.size.split(' ')[0] : String(item.europe),
          amount: values.washingLabel[idx]?.sizes[idx2]?.amount || '',
          amountWithDefect: values.washingLabel[idx]?.sizes[idx2]?.amountWithDefect || '',
          cn: item.cn,
          sCode: item.sCode || String(item.europe),
        })) || [];
        
        setFieldValue(`washingLabel[${idx}].sizes`, newSizes);
        setFieldValue(`washingLabel[${idx}].sizeDefectivePercent`, '');
      }
    };
    // useEffect içindeki koşullara bağlı olarak boyutları güncelleyin.
    // Eğer `orderNo` varsa ve ilgili `washingLabel` için `sizes` array'ı boş ise, veya `orderNo` yoksa güncelleme yapın.
    if (!orderNo || (orderNo && values.washingLabel[idx]?.sizes?.length === 0)) {
      updateSizes();

    }
    else{
      updateSizes2();
    }
  }, [cacheData,  orderNo,specialValue]);

  const totalAmount = values.washingLabel[idx].sizes?.reduce((acc, curr) => {
    if (curr.amount) return acc + parseInt(curr.amount)
    return acc
  }, 0)

  const totalAmountWithDefect = values.washingLabel[idx].sizes?.reduce((acc, curr) => {
    const amount = curr.amountWithDefect
    if (amount.length > 1) return acc + parseInt(amount)
    return acc
  }, 0)

  return (
    <div className="rounded border border-dashed p-2">
      <div className="mb-2 grid grid-cols-4 gap-x-5 text-sm font-medium text-simsek">
        <p>Beden</p>
        <p>Adet</p>
        <p>%</p>
        <p>Fireli Adet</p>
      </div>
      <div className="space-y-1">
        {values.washingLabel[idx].sizes.length < 1 ? (
          <div className="bg-rose-400 rounded p-2 text-center my-4">
            <p className="text-white text-sm">Lütfen beden tablosu seçiniz!</p>
          </div>
        ) : (
          values.washingLabel[idx].sizes.map((_, index) => {
            const amount = values.washingLabel[idx].sizes[index]?.amount
            const percent =
              (values.washingLabel[idx].sizes[index]?.amount *
                values.washingLabel[idx].sizeDefectivePercent) /
                100 || 0
            const wastageQuantity = String(parseInt(amount) + parseInt(percent))
            return (
              <div className="grid grid-cols-4 gap-x-5" key={index}>
                <Input
                  name={`washingLabel[${idx}].sizes[${index}].name`}
                  disabled
                  autoComplete="off"
                />
                <Input
                  name={`washingLabel[${idx}].sizes[${index}].amount`}
                  onWheel={(e) => e.target.blur()}
                  autoComplete="off"
                />
                <Input name={`washingLabel[${idx}].sizeDefectivePercent`} autoComplete="off" />
                <Input
                  name={`washingLabel[${idx}].sizes[${index}].amountWithDefect`}
                  autoComplete="off"
                  value={
                    values.washingLabel[idx].sizes[index].amount.length === 0
                      ? ' '
                      : wastageQuantity
                  }
                  disabled
                />
              </div>
            )
          })
        )}
        {values.washingLabel[idx].sizes.length > 0 && (
          <div className="grid grid-cols-4 gap-x-10">
            <p></p>
            <p className="text-sm font-semibold text-center">{totalAmount}</p>
            <p></p>
            <p className="text-sm font-semibold text-center">{totalAmountWithDefect}</p>
          </div>
        )}
      </div>
    </div>
  )
}
