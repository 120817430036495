import React from 'react';
import A4Page from '../A4Page';
import TopDetail from '../TopDetail';
import Page1 from './Page1';
import Page2 from './Page2';

const L60_25 = ({ data, selectColor,tab }, ref) => {
  // item.amount > 0 koşulunu sağlayan öğeleri filtrele ve her 8 öğede bir grupla
  const filteredAndGroupedSizes = data.washingLabel[selectColor].sizes
    .filter(item => item.amount > 0)
    .reduce((acc, item, index) => {
      const groupIndex = Math.floor(index / 7); // Her 8 öğe için bir grup indeksi
      if (!acc[groupIndex]) {
        acc[groupIndex] = []; // Yeni bir grup oluştur
      }
      acc[groupIndex].push(item);
      return acc;
    }, []);

  return (
    <div className="flex flex-col items-center" ref={ref}>
      {filteredAndGroupedSizes.map((group, groupIndex) => (
        <A4Page key={groupIndex} type="dikey">
          <div className="space-y-5">
            <TopDetail values={data} selectColor={selectColor} tab={tab} />
            <div className="space-y-3">
              {group.map((sizeData, index) => (
                <Page1
                  key={index}
                  selectColor={selectColor}
                  barcodeValue={`0${data?.articleNo?.split('/')[0]}${data?.articleNo?.split('/')[1]}${data?.washingLabel[selectColor]?.colorCode}${sizeData.sCode ? sizeData.sCode : ''}`}
                  data={data}
                  sizeData={sizeData}
                />
              ))}
            </div>
          </div>
        </A4Page>
      ))}
      {/* Her zaman en sonunda gösterilecek olan ikinci sayfa */}
      <A4Page type="dikey">
        <div className="space-y-5">
          <TopDetail values={data} selectColor={selectColor} tab={tab} />
          <Page2 data={data} tab={tab} />
        </div>
      </A4Page>
    </div>
  );
};

export default React.forwardRef(L60_25);
