import React from 'react'

import DatePicker from 'components/date-picker'
import { Button } from 'components/ui/button'
import { useCreateReport } from 'store/server/useReport'
import { RotateCcw } from 'lucide-react'
import Excel from './excel'
import { subDays } from 'date-fns'
export default function Report() {
  const [startDate, setStartDate] = React.useState(subDays(new Date(), 30))
  const [endDate, setEndDate] = React.useState(new Date())
  const [data, setData] = React.useState([])

  const { mutateAsync, isPending } = useCreateReport({ startDate, endDate })

  const handleSubmit = () => {
    mutateAsync({ startDate, endDate }).then((res) => setData(res.data))
  }

  return (
    <div className="bg-background p-10 rounded shadow-xl">
      <h1 className="text-4xl font-thin">Rapor oluştur</h1>
      <div className="flex flex-col justify-center items-center gap-10 mt-5">
        <div className="flex gap-x-5">
          <DatePicker date={startDate} setDate={setStartDate} labelText="Başlangıç Tarihi" />
          <DatePicker date={endDate} setDate={setEndDate} labelText="Bitiş Tarihi" />
        </div>
        <div>
          <Button className="w-80" onClick={handleSubmit} disabled={isPending}>
            {isPending && <RotateCcw className="animate-spin mr-2" size={16} />}
            Rapor Oluştur
          </Button>
        </div>
        {data.length > 0 && <Excel data={data} />}
      </div>
    </div>
  )
}
