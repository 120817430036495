import { SelectSymbol } from 'components/form/compobox/select-symbol'

export default function Symbol() {
  return (
    <div className="space-y-2 border border-dashed p-2 rounded">
      <SelectSymbol washType="wash" />
      <SelectSymbol washType="bleach" />
      <SelectSymbol washType="tumble" />
      <SelectSymbol washType="iron" />
      <SelectSymbol washType="dry" />
    </div>
  )
}
