import { useTotalCount } from 'store/server/useDashboard'
import React, { useEffect, useState,useCallback } from 'react';

import MainTitle from 'components/MainTitle'
import Spinner from 'components/spinner'
import { Input } from 'components/form/input';
import { SelectCurrency } from 'components/form/compobox/select-priceand-unit';
import CheckboxCustom from 'components/form/checkboxone';
import { useFormikContext } from 'formik'; // useFormikContext'i import edin
import AllUser from './alluser';
import { useNavigate } from 'react-router-dom'; // React Router v6 ve üstü için
import _ from 'lodash';
import useSearchStore from 'store/client/fomcreateUser';

export default function UserAddForm({data,onSearch,onClip}) {
  const { values, setFieldValue } = useFormikContext();
  const [searchTerm, setSearchTerm] = useState(onClip);
  const { searchStore, setSearch } = useSearchStore();

  
  console.log(values.UserList)
  const navigate = useNavigate();

  const handleAddUserClick = () => {
    navigate('/kullanıcılar/ekle'); // Belirlediğiniz URL'ye yönlendirme
  };

  const debouncedSearch = useCallback(_.debounce((searchTerm) => {
    setSearch(searchTerm);
    // Burada API çağrısı yapılabilir ve sonuçlar formik state'ine aktarılabilir
    
  }, 500), [setSearch]);

  useEffect(() => {
    debouncedSearch(searchStore);

  }, [searchStore, debouncedSearch]);

  useEffect(() => {
    // Formik state'ini güncelle (örneğin, arama sonuçları için)
    setFieldValue("UserList", data);
  }, [data, setFieldValue]);
  

  return (
    <div>
      <div style={{width:"100%",display:"grid",textAlign:"right"}}>
        <div style={{width:"100%"}}>
        <button onClick={handleAddUserClick} className='inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-simsek text-white shadow-sm hover:bg-sky-800 h-10 px-4 py-2'>
          Yeni Kullanıcı Ekle
        </button>
        <div style={{marginTop:10,marginBottom:15,display:"flex",justifyContent:"space-between"}}>
        <MainTitle>Kullanıcılar</MainTitle>
<div style={{width:"400px"}}>
        <Input
        name="search"
        placeholder="Kullanıcı Ara ... "
        autoComplete="off"
        value={searchStore}
        onChange={(e) => setSearch(e.target.value)}
      />
      </div>
      </div>
        </div>
      </div>
      <div className="flex">
        <div style={{width:"100%"}}>
        <div class="grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_7px] gap-x-5  rounded-lg p-5 items-center border-2 border-gray-400 border-opacity-0 hover:border-opacity-100 border-dashed transition-all duration-500 ease-in-out" style={{backgroundColor:"white",borderTop:"1px solid  rgba(57, 101, 159,.4) ",borderLeft:"1px solid  rgba(57, 101, 159,.4) ",borderRight:"1px solid  rgba(57, 101, 159,.4) "}}>
        <div className="my-2 px-2 w-full overflow-hidden" style={{textAlign:"center"}}>
      <span className='text-l font-bold text-simsek' >Şirket</span>
      </div>
        <div className="my-2 px-2 w-full overflow-hidden" style={{textAlign:"center"}}>
      <span className='text-l font-bold text-simsek' >E-Posta</span>
      </div>
      
      <div className="my-2 px-2 w-full overflow-hidden" style={{textAlign:"center"}}>
      <span className='text-l font-bold text-simsek' >Şifre</span>
      </div>

      <div className="my-2 px-2 w-full overflow-hidden" style={{textAlign:"center"}}>
      <span className='text-l font-bold text-simsek' >Kullanıcı Adı</span>
      </div>
      <div className="my-2 px-2 w-full overflow-hidden" style={{textAlign:"center"}}>
      <span className='text-l font-bold text-simsek' >Yetki</span>
      </div>

      <div className="my-2 px-2 w-full overflow-hidden" style={{textAlign:"center"}}>
      <span className='text-l font-bold text-simsek' >Aktiflik</span>
      </div>

      <div className="my-2 px-2 w-full overflow-hidden" style={{textAlign:"center"}}>
      <span className='text-l font-bold text-simsek' >Güncelle</span>
      </div>

      <div className="my-2 px-2 w-full overflow-hidden" style={{textAlign:"center"}}>
      <span className='text-l font-bold text-simsek' >Kullanıcıyı Sil</span>
      </div>
    </div>
        {values.UserList.map((user, idx) => (
            <AllUser key={idx} idx={idx} />
          ))}
        </div>  
    
      </div>
    </div>
  )
}
