import { Input } from 'components/form/input'
import { Button } from 'components/ui/button'
import { toast } from 'components/ui/use-toast'
import { Form, Formik } from 'formik'
import React from 'react'
import requests from 'service/api'

export default function YikamaEkle() {
  const [loading, setLoading] = React.useState(false)
  const initialValues = {
    name: '',
    translate: [
      {
        lang: 'SPANISH',
        translation: ''
      },
      {
        lang: 'ENGLISH',
        translation: ''
      },
      {
        lang: 'PORTUGUESE',
        translation: ''
      }
    ]
  }

  const handleSubmit = async (vall, { resetForm }) => {
    try {
      setLoading(true)
      await requests.order.addSymbolTranslate(vall)
      toast.success('Yıkama başarıyla eklendi')
      resetForm()
    } finally {
      setLoading(false)
    }
  }

  const handleTextareaChange = (e, setFieldValue) => {
    const lines = e.target.value.split('\n')
    lines.forEach((line, index) => {
      if (index < initialValues.translate.length) {
        setFieldValue(`translate[${index}].translation`, line)
      }
    })
  }

  return (
    <div className="">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ setFieldValue }) => (
          <Form className="space-y-2">
            <Input name="name" label="Yıkama Adı" />
            <textarea
              className="w-full h-32 p-2 border border-gray-300 rounded"
              onChange={(e) => handleTextareaChange(e, setFieldValue)}
            />
            <Button className="w-full" variant="green" type="submit" disabled={loading}>
              {loading ? 'Kaydediliyor...' : 'Kaydet'}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
