import { formatEmpty } from 'lib/formatting'
import { HeadphonesIcon } from 'lucide-react'

export default function CardItem({ data }) {
  return (
    <div className="grid grid-cols-[1fr_1fr_1fr_10px] gap-x-5 bg-gray-100 rounded-lg p-5 items-center border-2 border-gray-400 border-opacity-0 hover:border-opacity-100 border-dashed transition-all duration-500 ease-in-out">
      <div className="justify-self-start w-full">
        <div className="font-semibold text-s-blue ">
          <div className="">{data?.name}</div>
        </div>
        <div className="text-gray-600 text-sm flex items-center gap-x-1">
          <HeadphonesIcon className="w-4 h-4" /> {data?.authorizedPhone}
        </div>
      </div>
      <div className="justify-self-center">{data?.authorizedFullName}</div>
      <div className="justify-self-center">{formatEmpty(data?.code)}</div>
    </div>
  )
}
