import { FieldArray, useFormikContext } from 'formik'

import { Button } from 'components/ui/button'
import { Label } from 'components/ui/label'
import { SelectWashingAdditionalInstructions } from 'components/form/compobox/select-washing-additional-instructions'

export default function WashingAdditionalInstructions({ idx }) {
  const { values } = useFormikContext()
  return (
    <FieldArray name={`washingLabel[${idx}].washingAdditionalPhrases`}>
      {({ push }) => (
        <div>
          <Label className="text-simsek text-sm font-medium">Yıkama Ek İbareler</Label>
          <div className="space-y-2">
            {values.washingLabel[idx].washingAdditionalPhrases.map((item, idx2) => (
              <div key={idx2}>
                <SelectWashingAdditionalInstructions
                  idx={idx}
                  idx2={idx2}
                  name={`washingLabel[${idx}].washingAdditionalPhrases[${idx2}].id`}
                  serverValue={item}
                />
              </div>
            ))}
          </div>
          <div className="col-span-3 flex justify-center items-center mt-2">
            <Button type="button" onClick={() => push({ id: '' })} variant="ghost">
              + Yıkama Ek İbare Ekle
            </Button>
          </div>
        </div>
      )}
    </FieldArray>
  )
}
