import Barcode from 'react-barcode'

import { BASE_URL,BASE_URL2 } from 'config/axios'
import useOrderStore from 'store/client/orderStore'

import LabelCard from '../LabelCard'
import { GrupoLogo90 } from 'assets/icons'

export default function YatyPage1({ sizeData, data }) {
  const sizeCode = sizeData?.sCode
  const { referance } = useOrderStore()

  const barcodeValue = `0${data?.articleNo?.split('/')[0]}${data?.articleNo?.split('/')[1]}${
    data?.washingLabel[0]?.colorCode
  }${sizeCode ? sizeCode : ''}`

  const dataArray = Object.keys(data?.washingLabel[0]?.symbol).map((key) => ({
    key: key,
    value:
      typeof data?.washingLabel[0]?.symbol[key] === 'object'
        ? data?.washingLabel[0]?.symbol[key].value
        : data?.washingLabel[0]?.symbol[key]
  }))

  return (
    <div className="flex flex-wrap gap-3">
      <LabelCard type="front" number={1}>
        <div className="flex items-center h-full pl-[4.5mm] pr-[2mm]">
          <div className="flex items-center">
            <div className="text-white flex items-center">
              <GrupoLogo90 className="h-[22mm] w-4" />
            </div>
            <div className="-rotate-90 h-full whitespace-nowrap -ml-6 -mr-2">
              <p className="font-bold text-[10pt]">RN 77302</p>
            </div>
          </div>
          <div className="mt-2">
            <p className="text-[4.5pt]">
              INDITEX,S.A. AV. DIPUTACION ED.INDITEX ARTEIXO A CORUÑA (SPAIN) C.I.F. A-15075062
              TEXMODAS S.A.S SIC 900.123.408 ZARA ARGENTINA S.A. CUIT:30-69314836-3 V-
              {data.country.code} AZKA LOGISTICS LLC, TEXTILES ZANZIBAR S.A. RIF:N°J30536946-1
              REG.N°:0000051 TI-02 MACROMODA S.A. RUC 1792159105001 ZARA BRASIL LTDA. CNPJ:
              02952485000491 ZARA CANADA INC.CA 36685 아이티엑스코리아 자라코리아 서울시 강남구
              삼성동 무역센터 20층 080-479-0880 MA-MAROC: Sté AZZURRO IF: 1641920 (株)ITXジャパン
              (03-6415-8061) IMPORTADO POR ZARA MEXICO, S.A. DE C.V. PONIENTE 146 NO. 730 COLONIA
              INDUSTRIAL VALLEJO 'ALCALDIA AZCAPOTZALCO, C.P. 02300, CIUDAD DE MEXICO,
              RFC:ZME920824KM3
            </p>
            <div className="flex items-center justify-between">
              <div className="inline-flex flex-col">
                <Barcode
                  value={barcodeValue.length === 13 ? barcodeValue : '0390547180002'}
                  format="ITF14"
                  width={1}
                  height={19}
                  marginLeft={referance?.backgroundColor === 'B' ? 0 : 1}
                  marginRight={referance?.backgroundColor === 'B' ? 0 : 1}
                  marginTop={1}
                  marginBottom={1}
                  background={referance?.backgroundColor === 'B' && '#ffffff'}
                  displayValue={false}
                />
                <p className="tracking-[.20rem] self-center -mt-[1px]">{barcodeValue}</p>
              </div>
              <p className="font-arialMs text-[6pt] font-normal">{`${data.articleNo}/${data.washingLabel[0].colorCode}/${sizeData.name}`}</p>
              <p className="font-arialMs text-[6pt]">RUC 80089348-4</p>
              <p className="font-arialMs text-[6pt]">165/84A</p>
            </div>
          </div>
        </div>
      </LabelCard>
      <LabelCard type="back" number={2}>
        <div className="grid grid-cols-[1fr_11mm] h-full pl-[10mm] pr-[3.5mm] gap-x-2">
          <div className="flex items-center">
            <p className=" scale-y-[143%] leading-[5.25pt] text-[5.25pt]">
              {data.country.translate.map((translate) => translate.translate).join(' - ')}
            </p>
          </div>
          <div className="-rotate-90 -mr-10">
            <div className="flex items-center gap-x-1">
              {dataArray.map((item, idx) => (
                <img
                  key={idx}
                  src={`${BASE_URL2}/images/symbols/${item.key}/${item.value}.svg`}
                  className="w-[3.5mm]"
                  alt="sembol icon"
                />
              ))}
            </div>
            <div className="flex items-center gap-x-1">
              {dataArray.map((item, idx) => (
                <img
                  key={idx}
                  src={`${BASE_URL2}/images/symbols/${item.key}/${item.value}_1.svg`}
                  className="w-[3.5mm]"
                  alt="sembol icon"
                />
              ))}
            </div>
            <div className="flex items-center gap-x-1">
              {dataArray.map((item, idx) => (
                <img
                  key={idx}
                  src={`${BASE_URL2}/images/symbols/${item.key}/${item.value}_2.svg`}
                  className="w-[3.5mm]"
                  alt="sembol icon"
                />
              ))}
            </div>
          </div>
        </div>
      </LabelCard>
    </div>
  )
}
