import { useNavigate } from 'react-router-dom'

import { useBrandList } from 'store/server/useBrand'

import MainTitle from 'components/MainTitle'
import Spinner from 'components/spinner'
import { BASE_URL2 } from 'config/axios'

export default function ChoiceBrand() {
  const navigate = useNavigate()
  const { data, isFetching } = useBrandList()

  const nextStep = (name, id) => {
    navigate(`${id}_${name}`)
  }

  return (
    <div>
      <div className="mb-5">
        <MainTitle>Sipariş Ekle</MainTitle>
      </div>
      <div className="grid grid-cols-3 gap-5">
        {isFetching ? (
          <div className="col-span-3">
            <Spinner />
          </div>
        ) : (
          data.map((data) => (
            <div
              key={data.id}
              onClick={() => nextStep(data.name, data.id)}
              className="grid bg-gray-100 rounded-lg p-5 py-8 items-center border-2 border-gray-400 border-opacity-0 hover:border-opacity-100 border-dashed transition-all duration-500 ease-in-out cursor-pointer"
            >
              <div className="justify-self-center">
                <img src={`${BASE_URL2}${data?.logoPath}`} className="h-10" alt="marka ismi" />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}
