import { useQuery } from '@tanstack/react-query'

import requests from 'service/api'

import SearchSelect from '../search-select'
import { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'

export function SelectReferanceCode2({ name }) {
  const { values } = useFormikContext()
  const [selected, setSelected] = useState(null)

  const { data, isFetched } = useQuery({
    queryKey: ['/list', 'referance'],
    queryFn: async () => {
      const { data } = await requests.referance.list()
      return data
    },
    placeholderData: []
  })

  const isWasgingLabel = data.filter((item) => item.labelCategory === 'UYARI ETİKETİ')

  const options = isWasgingLabel.map(({ id, code }) => ({
    value: id,
    label: code
  }))

  useEffect(() => {
    if (values.warningLabel[0].referanceCode && isFetched) {
      const select = options.find(({ value }) => value === values.warningLabel[0].referanceCode)
      setSelected(select)
    }
    // eslint-disable-next-line
  }, [values.warningLabel[0].referanceCode, isFetched])

  return (
    <SearchSelect
      name={name}
      label="Referans Kodu"
      options={options}
      value={selected}
      onChange={(e) => setSelected(e)}
    />
  )
}
