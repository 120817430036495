import { Route, Routes } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

import NotFoundPage from 'pages/NotFoundPage'
import ProtectedRoutes from 'ProtectedRoutes'

//Layouts
import Root from 'layouts/Root'
import DashboardLayout from 'layouts/DashboardLayout'
import AuthLayout from 'layouts/AuthLayout'

//Auth
import LoginPage from 'pages/(auth)/Login'

//Sign-In
import HomePage from 'pages/(sign-in)/Home'
import ChoiceBrand from 'pages/(sign-in)/Order/Add/choice-brand'
import ChoiceDept from 'pages/(sign-in)/Order/Add/choice-dept'
import ChoiceSubDept from 'pages/(sign-in)/Order/Add/choice-sub-dept'
import AddOrderForm from 'pages/(sign-in)/Order/Add/Form'
import ArtWork2 from 'pages/(sign-in)/Order/ArtWork2'
import Brand from 'pages/(sign-in)/Brand'
import Company from 'pages/(sign-in)/Company'
import OrderList from 'pages/(sign-in)/Order'
import AddOrderFormDetail from 'pages/(sign-in)/Order/Add/Form/AddOrderFormDetail'
import YikamaEkle from 'pages/(sign-in)/yikama'
import Report from 'pages/(sign-in)/Report'
import AddCompany from 'pages/(sign-in)/Company/Add'
import UserAdd from 'pages/(sign-in)/UsersAdd/index'
import Addnewuser from 'pages/(sign-in)/UsersAdd/addnewuser'
import AdminProtectedRoute from 'protectedadmin'


const AllRoutes = () => {
  return (
    <Routes>
      <Route element={<Root />}>
        <Route index element={<Navigate to={'anasayfa'} replace />} />
        <Route element={<DashboardLayout />}>
          <Route element={<ProtectedRoutes />}>
            <Route path="anasayfa" element={<HomePage />} />
            <Route path="yikama" element={<YikamaEkle />} />
            <Route path="siparisler/listele" element={<OrderList />} />
            <Route path="siparisler/ekle" element={<ChoiceBrand />} />
            <Route path="siparisler/ekle/:brand" element={<ChoiceDept />} />
            <Route path="siparisler/ekle/:brand/:dept" element={<ChoiceSubDept />} />
            <Route path="siparisler/ekle/:brand/:dept/:subDept" element={<AddOrderForm />} />
            <Route
              path="siparisler/ekle/:brand/:dept/:subDept/:orderNo"
              element={<AddOrderFormDetail />}
            />
            <Route path="markalar" element={<Brand />} />
            <Route path="firmalar" element={<Company />} />
            <Route path="firmalar/ekle" element={<AddCompany />} />
            <Route path="raporlar" element={<Report />} />
            <Route path="kullanıcılar" element={<AdminProtectedRoute><UserAdd /></AdminProtectedRoute>} />
            <Route path="kullanıcılar/ekle" element={<AdminProtectedRoute><Addnewuser /></AdminProtectedRoute>} />

          </Route>
        </Route>
        <Route path="siparisler/artwork/:orderNo" element={<ArtWork2 />} />
        <Route element={<AuthLayout />}>
          <Route path="girisyap" element={<LoginPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}

export default AllRoutes
