import { API } from 'config/axios'

const requests = {
  auth: {
    login: (body) => API.post('/auth/login', body),
    register: (body) => API.post('/auth/register', body),
    updateUser: (body) => API.post('/auth/updateUser', body),
    getUsers: (id,search) => API.get('/auth/getUsers', { params: { id,search } }),
    deleteUser: (id) => API.delete(`/auth/deleteUser/${id}`), // Kullanıcı silme için DELETE isteği


  },
  dashboard: {
    getTotalCount: () => API.get('/list/getTotalCount')
  },

  company: {
    list: () => API.get('/list/company'),
    create: (body) => API.post('/company/create', body)
  },
  brand: {
    list: () => API.get('/list/brand'),
    listDepartment: (id) => API.get('/list/department/' + id),
    listSubDepartment: (id) => API.get('/list/subDepartment/' + id)
  },
  country: {
    list: () => API.get('/list/country')
  },
  size: {
    listGroup: (department) => API.get('/list/sizeGroup', { params: { department } })
  },
  referance: {
    list: (subDepartment) => API.get('/list/referance', { params: { subDepartment } })
  },
  symbol: {
    list: (washType) => API.get(`/list/symbol/${washType}`)
  },
  additionalPhrases: {
    list: () => API.get('/list/additionalPhrases'),
    washList: () => API.get('/list/washingAdditionalPhrases')
  },
  fabric: {
    list: () => API.get('/list/fabric'),
    typeList: () => API.get('/list/fabrictype'),
    lang: (name) => API.get('/list/fabricTranslate/' + name)
  },
  order: {
    create: (body) => API.post('/order/create', body),
    createwarning: (body) => API.post('/order/createwarning', body),
    createprice: (body) => API.post('/order/createprice', body),
    updateprice: (id, body) => API.post('/order/updateprice/' + id, body),
    updatewarning: (id, body) => API.post('/order/updatewarning/' + id, body),
    update: (id, body) => API.post('/order/update/' + id, body),
    updateStatus: (id, body) => API.post('/order/updateStatus/' + id, body),
    list: (status,page, search) => API.get('/order/list?status=' + status, { params: { page,search } }),
    get: (id) => API.get('/order/' + id),
    getArtworkOrder: (id, tab) => API.get('/list/artwork/' + id, { params: { tab } }),
    copyOrder: (id) => API.get('/order/copyOrder', { params: { id } }),
    addSymbolTranslate: (body) => API.post('/order/symbolTranslate', body)
  },
  report: {
    create: (params) => API.get('/report/create', { params }),
    createOrder: (params) => API.get('/report/createOrder', { params })
  },
  pdf: {
    createPDF: (body) => API.post('/pdf/create-pdf', body, { responseType: 'blob' }),
  },
}

export default requests
