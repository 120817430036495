import { useQuery } from '@tanstack/react-query'

import requests from 'service/api'

import { Label } from '@radix-ui/react-label'
import { CaretSortIcon } from '@radix-ui/react-icons'
import { Select } from 'antd'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { BASE_URL,BASE_URL2 } from 'config/axios'

export function SelectSymbol({ washType }) {
  const { setFieldValue, values } = useFormikContext()
  const [selected, setSelected] = useState(null)

  const [imgValue, setImgValue] = useState(null)

  const { data, isFetched } = useQuery({
    queryKey: ['/list', 'symbol', washType],
    queryFn: async () => {
      const { data } = await requests.symbol.list(washType)
      return data
    },
    enabled: !!washType,
    placeholderData: []
  })

  const options = data.group?.map(({ value, name }) => ({
    value,
    label: name
  }))

  useEffect(() => {
    if (values.washingLabel[0].symbol[washType] && isFetched) {
      const select = options.find(({ value }) => value === values.washingLabel[0].symbol[washType])
      setSelected(select)
      setImgValue(values.washingLabel[0].symbol[washType])
    }
    // eslint-disable-next-line
  }, [values.washingLabel[0].symbol[washType], isFetched])

  return (
    <div className="grid grid-cols-[80px_1fr_50px] items-center">
      <Label className="text-sm text-simsek font-medium uppercase">{washType}</Label>
      <Select
        showSearch
        suffixIcon={<CaretSortIcon />}
        className="h-10 w-full"
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={options}
        onSelect={(value, values) => {
          setFieldValue(`washingLabel[0].symbol.${washType}`, value)
          setImgValue(value)
        }}
        value={selected}
        onChange={(e) => setSelected(e)}
      />
      <div className="flex justify-center">
        {imgValue && (
          <img
            src={`${BASE_URL2}/images/symbols/${washType}/${imgValue}.svg`}
            alt="symbol logo"
            className="h-8"
          />
        )}
      </div>
    </div>
  )
}
