import { useRef,useState,useEffect } from 'react'

import { BASE_URL2 } from 'config/axios'

import LabelCard from '../LabelCard'
import MultiFabricLabels from './MultiFabricLabels'
import { FrLogo, ZaraQr } from 'assets/icons'
import useOrderStore from 'store/client/orderStore'

export default function Page2({ data }) {
  const { cards, lastNumber } = MultiFabricLabels({ data: data })

  let currentNumber = lastNumber
  const { referance } = useOrderStore()
  const ref = useRef(null)
  const isBlackLabel = referance?.backgroundColor === 'B'
  const symbol = data?.washingLabel[0]?.symbol

  const additionalPhrases = data.additionalPhrases
  const washingAdditionalPhrases = data.washingAdditionalPhrases

  const isSymbolTranslate = !!data.symbolTranslate
  const totalLabelCount = ref.current?.children.length + 2
  const [isEmptyLabel, setIsEmptyLabel] = useState(false);


    useEffect(() => {
      if (ref.current) {
        const totalLabelCount = ref.current.children.length + 2;
        const newIsEmptyLabel =
          (isSymbolTranslate && totalLabelCount % 2 === 0) ||
          (!isSymbolTranslate && totalLabelCount % 2 === 1);
  
        // isEmptyLabel state'ini güncelle
        setIsEmptyLabel(newIsEmptyLabel);
        
        // Opsiyonel: Toplam etiket sayısını göstermek için alert
      }
    }, [data]); // Boş bağımlılık dizisi, bu useEffect'in bileşen monte edildiğinde bir kez çalışmasını sağlar.
  
  return (
    <div className="flex flex-wrap gap-y-3 gap-x-5" ref={ref}>
      <LabelCard type="front" number={3}>
        <div className="flex items-center h-full">
          <p className="px-[2.5mm] scale-y-[159%] leading-[4.7pt] text-justify">
            {data.country.translate.map((translate) => translate.translate).join(' - ')}
          </p>
        </div>
      </LabelCard>
      {cards}
      {additionalPhrases.length > 0 && (
        <>
          {additionalPhrases.map((translation, index) => {
            let number = currentNumber++
            return (
              <LabelCard type={number % 2 === 0 ? 'front' : 'back'} number={number}>
                <div className="flex items-center h-full">
                  <div
                    className={`flex flex-col px-[2.5mm] ${
                      translation.length > 400
                        ? 'text-[4.4pt] leading-[4.4pt]'
                        : 'text-[4.7pt] leading-[4.7pt]'
                    } scale-y-[159%] font-arial text-justify`}
                  >
                    {translation.text}
                  </div>
                </div>
              </LabelCard>
            )
          })}
        </>
      )}
      {washingAdditionalPhrases.length > 0 && (
        <>
          {washingAdditionalPhrases.map((translation, index) => {
            let number = currentNumber++
            return (
              <LabelCard type={number % 2 === 0 ? 'front' : 'back'} number={number}>
                <div className="flex items-center h-full">
                  <div
                    className={`flex flex-col px-[2.5mm] ${
                      translation.length > 400
                        ? 'text-[3.8pt] leading-[3.8pt]'
                        : 'text-[5.04pt] leading-[5.05pt]'
                    } scale-y-[159%] tracking-[0.12mm] font-arial text-justify`}
                  >
                    {translation.text}
                  </div>
                </div>
              </LabelCard>
            )
          })}
        </>
      )}

      {isEmptyLabel && <LabelCard type="back" number={currentNumber++} />}

      <LabelCard type="front" number={currentNumber++}>
        <div className="flex items-center h-full ">
          <div className="pl-[13mm] pr-[12mm] pb-[0.5mm] w-full">
            <FrLogo className={`${isBlackLabel ? 'fill-white' : 'fill-[#1d1d1b]'}`} />
          </div>
        </div>
      </LabelCard>

      <LabelCard type="back" number={currentNumber++}>
        <div className="flex items-center h-full ">
          <div className="flex items-start justify-between px-[6mm] w-full">
            <div className="w-[21.5mm] h-[14mm] grid grid-cols-5 place-items-center items-start gap-y-1">
              {/* Ust sira */}
              <img
                src={`${BASE_URL2}/images/${isBlackLabel ? 'symbolsWhite' : 'symbols'}/wash/${
                  symbol['wash']
                }.svg`}
                alt="sembol icon"
              />
              <img
                src={`${BASE_URL2}/images/${isBlackLabel ? 'symbolsWhite' : 'symbols'}/bleach/${
                  symbol['bleach']
                }.svg`}
                alt="sembol icon"
              />
              <img
                src={`${BASE_URL2}/images/${isBlackLabel ? 'symbolsWhite' : 'symbols'}/tumble/${
                  symbol['tumble']
                }.svg`}
                alt="sembol icon"
              />
              <img
                src={`${BASE_URL2}/images/${isBlackLabel ? 'symbolsWhite' : 'symbols'}/iron/${
                  symbol['iron']
                }.svg`}
                alt="sembol icon"
              />
              <img
                src={`${BASE_URL2}/images/${isBlackLabel ? 'symbolsWhite' : 'symbols'}/dry/${
                  symbol['dry']
                }.svg`}
                alt="sembol icon"
              />
              {/* Orta Sira */}
              <img
                src={`${BASE_URL2}/images/${isBlackLabel ? 'symbolsWhite' : 'symbols'}/wash/${
                  symbol['wash']
                }_1.svg`}
                alt="sembol icon"
                style={{width:20,height:13.3}}

              />
              <img
                src={`${BASE_URL2}/images/${isBlackLabel ? 'symbolsWhite' : 'symbols'}/bleach/${
                  symbol['bleach']
                }_1.svg`}
                alt="sembol icon"
                style={{width:20,height:13.3}}

              />
              <img
                src={`${BASE_URL2}/images/${isBlackLabel ? 'symbolsWhite' : 'symbols'}/iron/${
                  symbol['iron']
                }_1.svg`}
                alt="sembol icon"
                style={{width:20,height:13.3}}

              />
              <img
                src={`${BASE_URL2}/images/${isBlackLabel ? 'symbolsWhite' : 'symbols'}/dry/${
                  symbol['dry']
                }_1.svg`}
                alt="sembol icon"
                style={{width:20,height:13.3}}

              />
              <img
                src={`${BASE_URL2}/images/${isBlackLabel ? 'symbolsWhite' : 'symbols'}/tumble/${
                  symbol['tumble']
                }_1.svg`}
                alt="sembol icon"
                style={{width:20,height:13.3}}

              />

              {/* Alt sira */}
              <img
                src={`${BASE_URL2}/images/${isBlackLabel ? 'symbolsWhite' : 'symbols'}/wash/${
                  symbol['wash']
                }_2.svg`}
                alt="sembol icon"
              />
              <img
                src={`${BASE_URL2}/images/${isBlackLabel ? 'symbolsWhite' : 'symbols'}/bleach/${
                  symbol['bleach']
                }_2.svg`}
                alt="sembol icon"
              />
              <img
                src={`${BASE_URL2}/images/${isBlackLabel ? 'symbolsWhite' : 'symbols'}/tumble/${
                  symbol['tumble']
                }_2.svg`}
                alt="sembol icon"
              />
              <img
                src={`${BASE_URL2}/images/${isBlackLabel ? 'symbolsWhite' : 'symbols'}/iron/${
                  symbol['iron']
                }_2.svg`}
                alt="sembol icon"
              />
              <img
                src={`${BASE_URL2}/images/${isBlackLabel ? 'symbolsWhite' : 'symbols'}/dry/${
                  symbol['dry']
                }_2.svg`}
                alt="sembol icon"
              />
            </div>
            <div className={`${isBlackLabel ? 'h-[13.5mm] w-[13.5mm]' : 'h-[14.6mm] w-[14.6mm]'} `}>
              <ZaraQr className={`fill:white bg-white ${isBlackLabel ? 'p-[0.1mm]' : ''}`} />
            </div>
          </div>
        </div>
      </LabelCard>

      {isSymbolTranslate && (
        <LabelCard type="front" number={currentNumber++}>
          <div className="px-[2.5mm] flex flex-col justify-center h-full leading-[4.4pt] scale-y-[1.3] mt-[1px]">
            {data.symbolTranslate.map((item) => (
              <div>{item}</div>
            ))}
          </div>
        </LabelCard>
      )}
    </div>
  )
}
