import { Button } from 'components/ui/button'
import { Copy } from 'lucide-react'
import React from 'react'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from 'components/ui/dialog'
import { useOrderCopy } from 'store/server/useOrder'
import { useParams } from 'react-router-dom'
import { RotateCcw } from 'lucide-react'

export default function CopyOrder({ no }) {
  const { orderNo } = useParams()
  const { mutateAsync, isPending } = useOrderCopy()
  const handleSubmit = () => {
    mutateAsync(orderNo).then(() => window.location.reload())
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button>
          <Copy size={20} className="mr-2" />
          Siparişi Kopyala
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Sipariş Kopyala</DialogTitle>
          <DialogDescription>
            <span className="font-bold">{no}</span> nolu siparişi kopyalamak istediğinizden emin
            misiniz?
          </DialogDescription>
        </DialogHeader>

        <DialogFooter className="sm:justify-end">
          <DialogClose asChild>
            <Button type="button" variant="secondary">
              Vazgeç
            </Button>
          </DialogClose>
          <Button type="button" onClick={handleSubmit} disabled={isPending}>
            {isPending && <RotateCcw className="animate-spin mr-2" size={20} />}
            Kopyala
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
