import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'components/ui/use-toast'
import { useNavigate } from 'react-router-dom'
import requests from 'service/api'

export function useCompany() {
  return useQuery({
    queryKey: ['/list', 'company'],
    queryFn: async () => {
      const { data } = await requests.company.list()
      return data
    },
    placeholderData: []
  })
}

export function useCreateCompany() {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: (body) => requests.company.create(body),
    onSuccess: () => {
      navigate('/firmalar')
      toast.success('Firma başarıyla oluşturuldu')
    }
  })
}
