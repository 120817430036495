import MainTitle from 'components/MainTitle';
import { Input } from 'components/form/input'; // Select componenti gerekiyorsa ekleyiniz
import requests from 'service/api';
import React from 'react';
import { toast } from 'components/ui/use-toast';
import useUserStore from 'store/client/fomcreateUser';
import { Form, Formik, Field } from 'formik'; // Field componenti Formik ile kullanılıyor
import { Button } from 'components/ui/button';
import { SelectCompany } from 'components/form/compobox/select-company';
export default function Addnewuser() {
    const { users, setUsers } = useUserStore();



    const handleSubmit = async (values) => {
      console.log(values.mail)
      try {
        const data = {
          mail: values.mail,
          username: values.username,
          isActive: true,
          tierType: values.tierType,
          password: values.password, // Backend tarafında tekrar hash'lenecek
          companyName:values.companyName,
          companyId:values.companyId
        };
        const allFieldsFilled = Object.values(values).every(value => value !== undefined && value !== null && value !== '');
        if (!allFieldsFilled) {
          toast.error('Tüm alanlar doldurulmalıdır.');
          return; // Veri eksikse fonksiyonu burada sonlandır
        }
        const response = await requests.auth.register(data); // Kullanıcı güncelleme isteği
        toast.success('Yeni Kullanıcı Eklendi');
        // Başarılı güncelleme mesajı veya işlemi burada yapabilirsiniz.
      } catch (error) {
        toast.error('Ekleme Başarısız');
        // Hata mesajını burada gösterebilirsiniz.
      }
    };


    return (
      <div>
        <MainTitle back>Yeni Kullanıcı Ekle</MainTitle>
        <Formik
          initialValues={{
              mail: '',
              username: '',
              password: '',
              userstype: 'USER',
              isActive: true,
              companyId:'',
              companyName:'',
          }}
          onSubmit={handleSubmit}
        >
          <Form className="space-y-5 bg-white p-5 rounded">
            <Input name="mail" label="E-posta" autoComplete="off" />
            <Input name="username" label="Kullanıcı Adı" autoComplete="off" />
            <Input name="password" label="Şifre" autoComplete="off" type="password" />
            
            <div  style={{maxWidth:"100%",display:"inline-block",width:"100%"}}>
            <SelectCompany name="companyId" labelName="companyName" changename="Firma Adı"></SelectCompany>
           </div>
            <div className="grid grid-cols-2 gap-5">
              <div className="flex grid-cols-[100px_1fr]">
                <label htmlFor="userstype" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-simsek"  style={{marginRight:15,width:"100px"}} >Kullanıcı Tipi</label>
                <Field as="select" name="userstype" className="rounded-md border border-gray-300 h-10 pl-3 pr-8 text-base placeholder-gray-600 focus:shadow-outline" style={{width:"100%"}}>
                  <option value="USER">USER</option>
                  <option value="ADMIN">ADMIN</option>
                </Field>
              </div>
         
              
              <div className="flex ">
                <label htmlFor="isActive" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-simsek" style={{marginRight:15,width:"100px"}}>Etkinlik Durumu</label>
                <Field as="select" name="isActive" className="rounded-md border border-gray-300 h-10 pl-3 pr-8 text-base placeholder-gray-600 focus:shadow-outline" style={{width:"100%"}}>
                  <option value={true}>Aktif</option>
                  <option value={false}>Pasif</option>
                </Field>
              </div>
            </div>
            <div className="flex justify-center" style={{marginTop:100}}>
              <Button type="submit" className="w-80">
                Ekle
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    );
}
