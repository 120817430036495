import React, { useEffect, useState, useCallback } from 'react';
import { Formik, Form } from 'formik';
import UserAddForm from './form';
import requests from 'service/api';
import { toast } from 'components/ui/use-toast';
import _ from 'lodash';
import useSearchStore from 'store/client/fomcreateUser';

export default function UserAdd() {
  const [users, setUsers] = useState([]);
  const { searchStore, setSearch } = useSearchStore();

  const fetchUsers = useCallback(_.debounce(async (search = '') => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.id) {
      try {
        // search parametresi boşsa tüm kullanıcıları çek, değilse arama yap
        const response = await requests.auth.getUsers(user.id, search);
        setUsers(response.data);
      } catch (error) {
        toast.error('Kullanıcılar yüklenirken bir hata oluştu');
      }
    }
  }, 500), []);

  useEffect(() => {
    // searchStore değeri ne olursa olsun fetchUsers çağrılacak
    fetchUsers(searchStore);
  }, [searchStore]);

  const handleSubmit = async (values) => {
    try {
      console.log('Form values:', values);
      toast.success('Kullanıcılar başarıyla eklendi');
    } catch (error) {
      toast.error('Kullanıcı eklerken bir hata oluştu');
    }
  };

  return (
    <Formik
      initialValues={{
        Users: [
          {
            mail: '',
            username: '',
            password: '',
            userstype: 'USER',
            isActive: true,
            companyName:'',
            companyId:''
          },
        ],
        search: '',
        UserList: users,
      }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {formikProps => (
        <Form>
          <UserAddForm data={users} />
        </Form>
      )}
    </Formik>
  );
}
