import { useState } from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from 'components/ui/tabs';
import WashingInstructions from './WashingInstructions';
import WarningInstructions from './WarningInstructions';
import PriceInstructions from './PriceInstructions';
import useTemNewStore from 'store/client/temNewStore'

export default function BottomSection() {
  const [currentTab, setCurrentTab] = useState("0"); // Başlangıç değerini 0 olarak ayarlayın
  const settabvalue = useTemNewStore((state) => state.settabvalue); // Zustand'dan fonksiyonu çekme

  const handleTabChange = (newValue) => {
    setCurrentTab(newValue);
    settabvalue(newValue); // Zustand state güncelleniyor

  };

  return (
    <Tabs defaultValue="0" onValueChange={handleTabChange}>
      <div className="flex justify-center">
        <TabsList>
          {/* `value` prop'larına sırasıyla "0", "1", "2" değerlerini atayın */}
          <TabsTrigger value="0">Yıkama Talimatı</TabsTrigger>
          <TabsTrigger value="1">Uyarı Talimatı</TabsTrigger>
          <TabsTrigger value="2">Fiyat Etiketi</TabsTrigger>
        </TabsList>
      </div>
      {/* Her bir `TabsContent` için `value` prop'unu uygun şekilde ayarlayın */}
      {/* Örneğin, burada sadece bir `TabsContent` kullanılmıştır ama her bir sekme için ayrı içerikler de kullanılabilir */}
      <TabsContent value="0">
        <div className="rounded bg-white p-5 shadow-xl">
          <WashingInstructions />
        </div>
      </TabsContent>
      <TabsContent value="1">
        <div className="rounded bg-white p-5 shadow-xl">
          <WarningInstructions />
        </div>
      </TabsContent>
      <TabsContent value="2">
        <div className="rounded bg-white p-5 shadow-xl">
          <PriceInstructions />
        </div>
      </TabsContent>
    </Tabs>

  );
}
