// AdminProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'store/client/authStore'; // useAuth hook'unuzun doğru yolu

const AdminProtectedRoute = ({ children }) => {
  const { user } = useAuth();

  if (!user || user.tierType !== 'ADMIN') {
    // Kullanıcı giriş yapmamış veya ADMIN değilse, hata sayfasına yönlendir
    return <Navigate to="/error" replace />;
  }

  return children;
};

export default AdminProtectedRoute;
