import { useCompany } from 'store/server/useCompany'

import SearchSelect from '../search-select'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

export function SelectCompany2({ name, labelName, defaultChoice }) {
  const { values } = useFormikContext()
  const { data, isFetched } = useCompany()

  const [selected, setSelected] = useState(null)

  const options = data.map(({ name, id }) => ({
    value: id,
    label: name
  }))

  useEffect(() => {
    if (isFetched) {
      // Öncelikle values.company'yi kontrol et
      let selectedOption ;

      // Eğer values.company boş ise ve defaultchoice varsa, bunu kullan
      if (!selectedOption && defaultChoice) {
        selectedOption = options.find(({ value }) => value === defaultChoice);
      }
      
      setSelected(selectedOption);
    }
    // eslint-disable-next-line
  }, [values.company, isFetched, defaultChoice])

  return (
    <SearchSelect
      label=""
      name={name}
      labelName={labelName}
      options={options}
      value={selected}
      onChange={(e) => setSelected(e)}
    />
  )
}
