import { useQuery } from '@tanstack/react-query'
import requests from 'service/api'

export function useTotalCount() {
  return useQuery({
    queryKey: ['/list', 'getTotalCount'],
    queryFn: async () => {
      const response = await requests.dashboard.getTotalCount()
      return response.data
    },
    placeholderData: {}
  })
}
