import React, { useState, useEffect } from 'react';
import { useOrderList } from 'store/server/useOrder';
import CardItem from './CardItem';
import Spinner from 'components/spinner';
import useSearchNewStore from 'store/client/useSearchStore';

export default function Canceled() {
  const [searchnew, setSearchNew] = useState('');

  const { specialValue, setSearchValue } = useSearchNewStore();
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useOrderList({
    status: 'CANCELLED',
    search: searchnew,
  });

  const [items, setItems] = useState([]);

  // Append new data as it arrives and flatten the nested structure
  useEffect(() => {
    if (data?.pages) {
      const newData = data.pages.flatMap(page => page).flat();
      setItems(newData);
      
    }
  }, [data]);


  // Handle search value changes
  useEffect(() => {
    if (specialValue !== searchnew) {
      setSearchNew(specialValue);
      setItems([]); // Clear items on new search
      fetchNextPage({ pageParam: 1 });
    }
  }, [specialValue]);

const handleScroll = () => {
  const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
  if (scrollTop + clientHeight >= scrollHeight - 150 && hasNextPage && !isFetchingNextPage) {
    const nextPage = currentPage + 1;
    fetchNextPage({ pageParam: nextPage }).then(() => {
      setCurrentPage(nextPage);  // Sayfa yüklemesi başarılı olduktan sonra sayfa numarasını güncelle
    });
  }
};
useEffect(() => {
  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, [hasNextPage, isFetchingNextPage,]);


  if (items.length === 0 && !isFetchingNextPage) {
    return <p>No data available.</p>;
  }

  return (
    <div className="space-y-5">
      {items.map((item, index) => (
        <CardItem key={index} data={item} /> // Consider using unique IDs if available
      ))}
      {isFetchingNextPage && <Spinner />}
    </div>
  );
}
