import { FieldArray, useFormikContext } from 'formik'

import { SelectFabricList } from 'components/form/compobox/select-fabric-list'
import { SelectFabricType } from 'components/form/compobox/select-fabric-type'
import { Input } from 'components/form/input'
import { X } from 'lucide-react'
import { Button } from 'components/ui/button'

export default function Fabric({ idx }) {
  const { values } = useFormikContext()

  const totalPercentage = values.washingLabel[idx].fabric.map((fabric) =>
    fabric.mix.reduce((acc, curr) => acc + Number(curr.percent), 0)
  )
  const checkDuplicateMixTypes = () => {
    const duplicateMixTypes = values.washingLabel[idx].fabric.map((fabric) =>
      fabric.mix.map((mix) => mix.type)
      .some((type, index, array) => array.indexOf(type) !== index && type !== '')
    );
    return duplicateMixTypes;
  };
  const duplicateMixTypes = checkDuplicateMixTypes();
  const checkMixPercentOrder = () => {
    return values.washingLabel[idx].fabric.map((fabric) =>
      fabric.mix.some((mix, index, array) => 
        index > 0 && Number(mix.percent) > Number(array[index - 1].percent))
    );
  };

  
  const incorrectMixPercentOrder = checkMixPercentOrder();

  return (
    <div className={`${idx > 0 && 'hidden'} col-span-2 border border-dashed p-2 rounded`}>
      <div className="bg-gray-100 p-2 text-sm rounded mb-2">
        {values.washingLabel[idx].fabric.map((fabric, idx2) => (
          <div
            className={`${totalPercentage[idx2] > 100 && 'bg-red-400 text-white'} ${
              totalPercentage[idx2] < 100 && 'bg-red-400 text-white'
            } ${totalPercentage[idx2] === 100 && 'bg-green-300 text-black'} px-2 my-1`}
            key={`fabric-${idx2}`}  // Benzersiz key ekliyoruz
          >
            {fabric.name} Toplam Karışım Yüzdesi:{' '}
            <span className="font-bold">%{totalPercentage[idx2]}</span>
          </div>
        ))}
        {duplicateMixTypes.some((hasDuplicate) => hasDuplicate) && (
        <div className="bg-red-500 text-white p-2 text-sm rounded mb-2">
          Aynı kumaş tipi seçilemez.
        </div>
      )}
      {incorrectMixPercentOrder.some((isIncorrect) => isIncorrect) && (
          <div className="bg-yellow-500 text-white p-2 text-sm rounded mb-2">
            Karışım yüzdeleri yanlış sıralandı: Sol taraftaki değer sağ taraftakinden büyük olmalıdır.
          </div>
        )}
      </div>
      <div className="grid grid-cols-[2fr_5fr] gap-x-5 text-sm text-simsek font-medium mb-2">
        <div>Kumaş Tipi</div>
        <div className="grid grid-cols-2 gap-x-5 pr-14">
          <div>%</div>
          <div>%</div>
        </div>
      </div>
      <FieldArray name={`washingLabel[${idx}].fabric`}>
        {({ push, remove }) => (
          <div className="space-y-5">
            {values.washingLabel[idx].fabric.map((fabric, idx2) => (
              <div className="grid grid-cols-[2fr_5fr] gap-2 relative" key={`fabric-list-${idx2}`}>
                <SelectFabricList
                  name={`washingLabel[${idx}].fabric[${idx2}].name`}
                  idx={idx}
                  idx2={idx2}
                />
                <FieldArray name={`washingLabel[${idx}].fabric[${idx2}].mix`}>
                  {({ push }) => (
                    <div className="grid grid-cols-2 items-center gap-2 pr-14">
                      {values.washingLabel[idx].fabric[idx2].mix.map((_, idx3) => (
                        <div className="grid grid-cols-[50px_1fr] gap-x-2" key={`fabric-mix-${idx2}-${idx3}`}>
                          <Input
                            name={`washingLabel[${idx}].fabric[${idx2}].mix[${idx3}].percent`}
                            type="number"
                          />
                          <SelectFabricType
                            name={`washingLabel[${idx}].fabric[${idx2}].mix[${idx3}].type`}
                            idx={idx}
                            idx2={idx2}
                            idx3={idx3}
                          />
                        </div>
                      ))}
                      <Button
                        type="button"
                        size="sm"
                        variant="yellow"
                        onClick={() => push({ percent: '', type: '' })}
                      >
                        + Karışım Ekle
                      </Button>
                    </div>
                  )}
                </FieldArray>
                <Button
                  variant="ghost"
                  onClick={() => remove(idx2)}
                  className="absolute right-0 top-1/2 -translate-y-1/2 -mt-5"
                >
                  <X className="w-4 h-4 text-red-500" />
                </Button>
              </div>
            ))}
            <div className="col-span-3 flex justify-center mt-2">
              <Button
                type="button"
                onClick={() =>
                  push({
                    name: '',
                    mix: [
                      { percent: '', type: '' },
                      { percent: '', type: '' }
                    ]
                  })
                }
                variant="ghost"
              >
                + Kumaş Tipi Ekle
              </Button>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  )
}
