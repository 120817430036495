import axios from 'axios'

//export const BASE_URL = 'http://5.180.186.195:5556/api/v1'
export const BASE_URL = 'https://excel.progressytems.com/api/v1'
//export const BASE_URL2 = 'https://excel.progressytems.com/api/v1'
//export const BASE_URL = 'http://localhost:5555/api/v1'
export const BASE_URL2 = 'https://excel.progressytems.com'
//export const BASE_URL2 = 'http://localhost:5555'

//export const BASE_URL2 = 'http://localhost:3000'

export const API = axios.create({ baseURL: BASE_URL })

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location.reload()
    }
  }
)
