import { useQuery } from '@tanstack/react-query'

import requests from 'service/api'

import SearchSelect from '../search-select'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

export function SelectMadeIn({ name }) {
  const { values } = useFormikContext()
  const [selected, setSelected] = useState(null)

  const { data, isFetched } = useQuery({
    queryKey: ['/list', 'country'],
    queryFn: async () => {
      const { data } = await requests.country.list()
      return data
    },
    placeholderData: []
  })

  const options = data.map(({ name, id }) => ({
    label: name,
    value: id
  }))

  useEffect(() => {
    if (values.country && isFetched) {
      const select = options.find(({ value }) => value === values.country)
      setSelected(select)
    }
    // eslint-disable-next-line
  }, [values.country, isFetched])

  return (
    <SearchSelect
      label="Made In"
      options={options}
      name={name}
      value={selected}
      onChange={(e) => setSelected(e)}
    />
  )
}
