import { FieldArray, useFormikContext } from 'formik'
import { useParams,useNavigate  } from 'react-router-dom'
import { RotateCcw } from 'lucide-react'

import { useOrderSave, useOrderUpdate } from 'store/server/useOrder'

import { Input } from 'components/form/input'
import { Input as NoFormInput } from 'components/ui/input'
import { Button } from 'components/ui/button'
import { Separator } from 'components/ui/separator'
import { SelectReferanceCode } from 'components/form/compobox/select-referance-code'
import Sizes from './Sizes'
import Symbol from './Symbol'
import Fabric from './Fabric'
import AdditionalInstructions from './Addition/AdditionalInstructions'
import WashingAdditionalInstructions from './Addition/WashingAdditionalInstructions'
import { Link } from 'react-router-dom'
import { X } from 'lucide-react'
import { toast } from 'components/ui/use-toast'

export default function WashingInstructions({ currentTab }) {
  const { values, setFieldValue } = useFormikContext()
  const { mutate: saveOrder, isPending } = useOrderSave()
  const { mutate: updateOrder, isPending: isPendingUpdate } = useOrderUpdate()
  const { brand, orderNo, dept, subDept } = useParams()
  const brandId = brand.split('_')[0];

  const navigate = useNavigate();

  const handleButtonClick = () => {
    const errors = [] // Hata mesajlarını saklamak için bir dizi

    values.washingLabel.forEach((label, labelIndex) => {
      // Her bir fabric için aynı 'type' değerlerini kontrol et
      label.fabric.forEach((fabric, fabricIndex) => {
        const typeCounts = fabric.mix.reduce((acc, mix) => {
          acc[mix.type] = (acc[mix.type] || 0) + 1
          return acc
        }, {})

        // Eğer bir 'type' birden fazla kez bulunursa, hata mesajı ekle
        Object.entries(typeCounts).forEach(([type, count]) => {
          if (count > 1) {
            errors.push(
              `Yenilenen değerler '${type}' type found in Fabric ${fabricIndex + 1} of Label ${labelIndex + 1}.`
            )
          }
        })
      })
    })

    // Eğer hata mesajı varsa, uyarı göster ve işlemi durdur
    if (errors.length > 0) {
      toast.error(errors.join('\n'))
      return; // İşlemi durdurur
    }
    const incorrectOrderExists = values.washingLabel.some((label) =>
      label.fabric.some((fabric) =>
        fabric.mix.some(
          (mix, index, array) => index > 0 && Number(mix.percent) > Number(array[index - 1].percent)
        )
      )
    )

    if (incorrectOrderExists) {
  
      toast.error('Karışım yüzdeleri yanlış sıralandı: Sol taraftaki değer sağ taraftakinden büyük olmalıdır.')
      return ;
    }
    const exceedsHundred = values.washingLabel.some((label) =>
    label.fabric.some((fabric) => {
      const totalPercent = fabric.mix.reduce((sum, mix) => sum + Number(mix.percent), 0);
      return totalPercent !== 100;
    }) 
  );
  if(exceedsHundred)
  {
    toast.error('Karışım 100 den büyük veya küçükolamaz');
    return;
  }
 

    navigate(`/siparisler/artwork/${orderNo}?tab=${0}`);
  };
  const handleSaveOrder = () => {

    const department = `${brand.split('_')[1]} / ${dept.split('_')[1]} / ${subDept}`
    const filteredValues = values.washingLabel.map((label) => {
      const filteredFabric = label.fabric.map((fabricItem) => {
        const filteredMix = fabricItem.mix.filter((mix) => mix.percent > 0)
        return { ...fabricItem, mix: filteredMix }
      })

      return { ...label, fabric: filteredFabric }
    })
    const path = `${brand}/${dept}/${subDept}`

    const errors = [] // Hata mesajlarını saklamak için bir dizi

    values.washingLabel.forEach((label, labelIndex) => {
      // Her bir fabric için aynı 'type' değerlerini kontrol et
      label.fabric.forEach((fabric, fabricIndex) => {
        const typeCounts = fabric.mix.reduce((acc, mix) => {
          acc[mix.type] = (acc[mix.type] || 0) + 1
          return acc
        }, {})

        // Eğer bir 'type' birden fazla kez bulunursa, hata mesajı ekle
        Object.entries(typeCounts).forEach(([type, count]) => {
          if (count > 1) {
            errors.push(
              `Yenilenen değerler '${type}' type found in Fabric ${fabricIndex + 1} of Label ${labelIndex + 1}.`
            )
          }
        })
      })
    })

    // Eğer hata mesajı varsa, uyarı göster ve işlemi durdur
    if (errors.length > 0) {
      toast.error(errors.join('\n'))
      return; // İşlemi durdurur
    }
    const incorrectOrderExists = values.washingLabel.some((label) =>
      label.fabric.some((fabric) =>
        fabric.mix.some(
          (mix, index, array) => index > 0 && Number(mix.percent) > Number(array[index - 1].percent)
        )
      )
    )

    if (incorrectOrderExists) {
  
      toast.error('Karışım yüzdeleri yanlış sıralandı: Sol taraftaki değer sağ taraftakinden büyük olmalıdır.')
      return ;
    }
    const exceedsHundred = values.washingLabel.some((label) =>
    label.fabric.some((fabric) => {
      const totalPercent = fabric.mix.reduce((sum, mix) => sum + Number(mix.percent), 0);
      return totalPercent !== 100;
    }) 
  );
  if(exceedsHundred)
  {
    toast.error('Karışım 100 den büyük veya küçükolamaz');
    return;
  }
    saveOrder({ ...values, washingLabel: filteredValues, brand: brandId, department, path })
  }

  const hasDuplicateFabricTypes = () => {
    return values.washingLabel.some((label) => {
      const fabricTypes = label.fabric.flatMap((fabric) => fabric.mix.map((mix) => mix.type))
      const uniqueFabricTypes = new Set(fabricTypes)
      return uniqueFabricTypes.size !== fabricTypes.length
    })
  }

  const handleUpdateOrder = () => {
    const errors = [] // Hata mesajlarını saklamak için bir dizi

    values.washingLabel.forEach((label, labelIndex) => {
      // Her bir fabric için aynı 'type' değerlerini kontrol et
      label.fabric.forEach((fabric, fabricIndex) => {
        const typeCounts = fabric.mix.reduce((acc, mix) => {
          acc[mix.type] = (acc[mix.type] || 0) + 1
          return acc
        }, {})

        // Eğer bir 'type' birden fazla kez bulunursa, hata mesajı ekle
        Object.entries(typeCounts).forEach(([type, count]) => {
          if (count > 1) {
            errors.push(
              `Yenilenen değerler '${type}' type found in Fabric ${fabricIndex + 1} of Label ${labelIndex + 1}.`
            )
          }
        })
      })
    })

    // Eğer hata mesajı varsa, uyarı göster ve işlemi durdur
    if (errors.length > 0) {
      toast.error(errors.join('\n'))
      return // İşlemi durdurur
    }
    const incorrectOrderExists = values.washingLabel.some((label) =>
      label.fabric.some((fabric) =>
        fabric.mix.some(
          (mix, index, array) => index > 0 && Number(mix.percent) > Number(array[index - 1].percent)
        )
      )
    )
    const exceedsHundred = values.washingLabel.some((label) =>
    label.fabric.some((fabric) => {
      const totalPercent = fabric.mix.reduce((sum, mix) => sum + Number(mix.percent), 0);
      return totalPercent !== 100;
    }) 
  );
  if(exceedsHundred)
  {
    toast.error('Karışım 100 den büyük veya küçükolamaz');
    return;
  }
 

    if (incorrectOrderExists) {
  
      toast.error('Karışım yüzdeleri yanlış sıralandı: Sol taraftaki değer sağ taraftakinden büyük olmalıdır.')
      return // Yanlış sıralama varsa işlemi durdur.
    }

    // Tekrar eden kumaş türü yoksa, güncelleme işlemini gerçekleştir
    const filteredValues = values.washingLabel.map((label) => {
      const filteredFabric = label.fabric.map((fabricItem) => {
        const filteredMix = fabricItem.mix.filter((mix) => mix.percent > 0)
        return { ...fabricItem, mix: filteredMix }
      })

      return { ...label, fabric: filteredFabric }
    })
    delete values.brandId
    updateOrder({ id: orderNo, body: { ...values, washingLabel: filteredValues, brand: brandId } })
  }

  return (
    <FieldArray name="washingLabel">
      {({ push, remove }) => (
        <div>
          {values.washingLabel.map((_, idx) => (
            <div className="grid grid-cols-2 gap-5 pb-5 relative" key={idx}>
              <div className="col-span-2 grid grid-cols-2 gap-2 rounded border border-dashed p-2">
            
                    <NoFormInput
                      label="Müşteri Sipariş No"
                      value={values.washingLabel[idx]?.customerOrderNo}
                      onChange={(e) => {
                        if (values.changesForEveryProduct) {
                          setFieldValue(`washingLabel[${idx}].customerOrderNo`, e.target.value)
                        }
                      
                      }}
                    />

                    <Input
                      label="Müşteri Sipariş Detayı"
                      name={`washingLabel[${idx}].customerOrderDetail`}
                      autoComplete="off"
                    />

                    <Input
                      label="Renk Kodu"
                      name={`washingLabel[${idx}].colorCode`}
                      maxLength="3"
                      autoComplete="off"
                    />

                    <div className={`${idx > 0 ? 'hidden' : ''}`}>
                      <SelectReferanceCode name={`washingLabel[${idx}].referanceCode`} />
                    </div>
           
      
              </div>
              <div className="col-span-2 grid grid-cols-2 gap-x-5">
                <Sizes idx={idx}/>
       
                  <div className={`${idx > 0 && 'hidden'}`}>
                    <Symbol />
                  </div>
    
             

              </div>
              
                  <Fabric idx={idx} />
                  <div
                    className={`${
                      idx > 0 ? 'hidden' : ''
                    } col-span-2 grid grid-cols-2 gap-5 rounded border border-dashed p-2`}
                  >
                    <AdditionalInstructions idx={idx} />
                    <WashingAdditionalInstructions idx={idx} />
                  </div>
                  <Separator className="col-span-2 h-[5px] bg-simsek" />
                  {idx > 0 && (
                    <Button
                      variant="destructive"
                      onClick={() => remove(idx)}
                      className="absolute -right-40 top-0"
                    >
                      <X className="w-4 h-4 text-white" />
                      Renk Sil
                    </Button>
                  )}
            
     
            </div>
          ))}
          <div className="flex items-center gap-x-5">
            {!orderNo && (
              <Button
                type="submit"
                variant="softgreen"
                onClick={handleSaveOrder}
                disabled={isPending}
                className="w-full"
              >
                {isPending && <RotateCcw className="animate-spin mr-2" size={16} />}
                Kaydet
              </Button>
            )}
            <Button
              type="button"
              onClick={orderNo ? handleUpdateOrder : handleSaveOrder}
              disabled={isPending || isPendingUpdate}
              className="w-full"
              variant={orderNo && 'softgreen'}
            >
              {(isPending || isPendingUpdate) && (
                <RotateCcw className="animate-spin mr-2" size={16} />
              )}
              {orderNo ? 'Güncelle' : "Artwork'e Git"}
            </Button>
            {orderNo && (
              <Button
          type="button"
          disabled={isPending}
          className="w-full"
          onClick={handleButtonClick}
        >
          {isPending && <RotateCcw className="animate-spin mr-2" size={16} />}
          Artwork'e Git
        </Button>
            )}
            <Button
              type="button"
              variant="blue"
              onClick={() => {
                push({
                  ...values.washingLabel[0],
                  colorCode: '',
                  sizeDefectivePercent: '',
                  sizes: values.washingLabel[0].sizes.map((size) => ({
                    ...size,
                    amount: '',
                    amountWithDefect: ''
                  }))
                })
              }}
              className="w-full"
            >
              Renk Ekle
            </Button>
          </div>
        </div>
      )}
    </FieldArray>
  )
}
