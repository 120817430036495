import { useQuery } from '@tanstack/react-query'

import requests from 'service/api'

import SearchSelect from '../search-select'
import { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'

export function SelectFabricList({ name, idx, idx2 }) {
  const { values } = useFormikContext()
  const [selected, setSelected] = useState(null)

  const { data, isFetched } = useQuery({
    queryKey: ['/list', 'fabric'],
    queryFn: async () => {
      const { data } = await requests.fabric.list()
      return data
    },
    placeholderData: []
  })

  const options = data.map(({ name }) => ({
    label: name,
    value: name
  }))

  useEffect(() => {
    if (values.washingLabel[idx].fabric[idx2].name && isFetched) {
      const select = options.find(
        ({ value }) => value === values.washingLabel[idx].fabric[idx2].name
      )
      setSelected(select)
    }
    // eslint-disable-next-line
  }, [values.washingLabel[idx].fabric[idx2].name, isFetched])

  return (
    <SearchSelect
      className="grid-cols-1 items-start"
      name={name}
      options={options}
      value={selected}
      onChange={(e) => setSelected(e)}
    />
  )
}
