import { Select } from 'antd'
import { Label } from 'components/ui/label'
import { CaretSortIcon } from '@radix-ui/react-icons'
import { useFormikContext } from 'formik'
import { twMerge } from 'tailwind-merge'

export default function SearchSelect({ options, label, name, className,labelName, ...props }) {
  const { setFieldValue } = useFormikContext()
  const cn = twMerge(`grid ${label ? 'grid-cols-[100px_1fr]' : ''} items-center`, className)

  return (
    <div className={cn} style={{maxWidth:"100%"}}>
      {label && (
        <Label className="text-simsek" htmlFor={name}>
          {label}
        </Label>
      )}
      <Select
        id={name}
        showSearch
        allowClear
        onClear={() => {
          setFieldValue(name, '');
          if (labelName) {
            setFieldValue(labelName, ''); // Clear the labelName field as well
          }
        }}
        suffixIcon={<CaretSortIcon />}
        className="h-10"
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={options}
        onSelect={(value, option) => {
          setFieldValue(name, value);

          if (labelName && option) {

            setFieldValue(labelName, option.label); // Set the label to a new field in Formik state
          }
        }}
        {...props}
      />
    </div>
  )
}
