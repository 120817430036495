import React from 'react'
import A4Page from '../A4Page'
import TopDetail from '../TopDetail'
import Page1 from './Page1'
import Page2 from './Page2'

const L120_25 = ({ data, selectColor ,tab}, ref) => {
  return (
    <div className="flex flex-col items-center" ref={ref}>
      <A4Page type="yatay">
        <div className="space-y-5">
          <TopDetail values={data} selectColor={selectColor} tab={tab}/>
          <div className="space-y-3">
            {data.washingLabel[selectColor].sizes
              .filter((item) => item.amount > 0)
              .map((sizeData, index) => (
                <Page1
                  key={index}
                  selectColor={selectColor}
                  barcodeValue={`0${data?.articleNo?.split('/')[0]}${
                    data?.articleNo?.split('/')[1]
                  }${data?.washingLabel[0]?.colorCode}${sizeData.sCode ? sizeData.sCode : ''}`}
                  data={data}
                  sizeData={sizeData}
                />
              ))}
          </div>
        </div>
      </A4Page>
      <A4Page type="yatay">
        <div className="space-y-5">
          <TopDetail values={data} />
          <Page2 data={data} />
        </div>
      </A4Page>
    </div>
  )
}

export default React.forwardRef(L120_25)
