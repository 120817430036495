import { useMutation, useQuery } from '@tanstack/react-query'

import requests from 'service/api'

export function useCreateReport(params) {
  return useMutation({
    mutationKey: ['/report/create', params],
    mutationFn: () => requests.report.create(params)
  })
}

export function useCreateOrderReport(params) {
  return useQuery({
    queryKey: ['/report/createOrder', params],
    queryFn: async () => {
      const respons = await requests.report.createOrder(params)
      return respons.data
    }
  })
}
