import React, { useRef, useEffect } from 'react';
import Barcode from 'react-barcode'

import LabelCard from '../LabelCard'
import { GrupoLogo } from 'assets/icons'
import useOrderStore from 'store/client/orderStore'

export default function Page1({ sizeData, selectColor = 0, barcodeValue, data }) {
  const { referance } = useOrderStore()
  const isBlack = referance?.backgroundColor === 'B'
  const barcodeRef = useRef(null);
  console.log(sizeData.cn);
  useEffect(() => {
    const svgElement = barcodeRef.current.querySelector('svg');
    const textElement = svgElement?.querySelector('text');
    if (textElement) {
      textElement.style.fill = isBlack ? 'white' : ''; // isBlack true ise siyah, değilse kırmızı yap
    }
  }, [barcodeValue, isBlack]);  // barcodeValue veya isBlack değiştiğinde bu kod bloğunu çalıştır

  return (
    <div  ref={barcodeRef} className="flex flex-wrap gap-3">
      <LabelCard type="front" number={1}>
        <div className="pl-[4mm] pr-[7.5mm] pt-[2mm]">
        {isBlack && (
        <div style={{ backgroundColor: "white", height: 19, width: 156, position: "absolute", top: 8.4 }}></div>
      )}
          <div className="flex">
            {isBlack && <div className="h-[19px] w-3  mt-[1px]" />}
            <div className="inline-flex flex-col tracking-[.10rem]">
              <Barcode
                value={barcodeValue.length === 13 ? barcodeValue : '0390547180002'}
                format="ITF14"
                width={1}
                height={19}
                marginLeft={1}
                marginRight={1}
                marginTop={1}
                marginBottom={1}  
                background="transparent"
                lineColor={isBlack ? '#000000' : '#000000'}
                fontSize={9}
                font="Arial"
              />
            </div>
 
       

            {isBlack && <div className="h-[19px] w-3  mt-[1px]" />}
          </div>
          <div className="mt-1">
            <div className="flex w-full justify-between">
              <p className="font-arialMs text-[6pt] font-normal">{`${data.articleNo}/${data.washingLabel[selectColor].colorCode}/${sizeData.name}`}</p>
              <p className="font-arialMs text-[7pt]">{sizeData.cn}</p>
            </div>
            <div className="flex w-full justify-between">
              <p className="font-arialNarrow text-[7pt]">RUC 80089348-4</p>
              <p className="font-arialNarrow text-[7pt]">RN 77302</p>
            </div>
            <div className="flex justify-center">
              <div className="w-[22mm] ml-4">
                <GrupoLogo />
              </div>
            </div>
          </div>
        </div>
      </LabelCard>
      <LabelCard type="back" number={2}>
        <div className="flex items-center h-full">
          <p className="scale-y-[158.7%] leading-[5.7pt] -tracking-[0.20px] px-[2.5mm]">
            INDITEX,S.A. AV. DIPUTACION ED.INDITEX ARTEIXO A CORUÑA (SPAIN) C.I.F. A-15075062
            TEXMODA S.A.S SIC 900.123.408 TAC ARGENTINA S.A. CUIT:30-69314836-3{' '}
            <span>
              V-
              {data?.country?.code}
            </span>
            -21 AZKA LOGISTICS LLC, TEXTILES ANKA 22, C.A. - RIF.: J-50434538-5 REG N: 0008962TI-24
            MACROMODA S.A. RUC 1792159105001 INDITEX BRASIL LTDA. CNPJ:02952485000491  ITX CANADA LTD. CA 36685
          </p>
        </div>
      </LabelCard>
    </div>
  )
}
