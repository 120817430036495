import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import AwaitingApproval from './(status)/AwaitingApproval';
import InProduction from './(status)/InProduction';
import Dispatched from './(status)/Dispatched';
import Canceled from './(status)/Canceled';
import Wait from './(status)/Wait';
import React, { useState } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons'; // Arama ikonu için
import useSearchNewStore from 'store/client/useSearchStore';
const { Search } = Input;

export default function OrderList() {
  const [searchValue, setSearchValuee] = useState('');


  const { specialValue, setSearchValue } = useSearchNewStore(); // Zustand hook'u kullanarak zustand durumunu ve işlevlerini alın

  const handleSearch = (event) => {
    const { value } = event.target; // input'taki yeni değeri çıkar
    setSearchValuee(value); // Yerel state'i güncelle
    setSearchValue(value)

    // Burada arama işlemini gerçekleştirerek sonuçları filtreleyebilirsiniz
  };


  return (
    <div>
      <div className="">
        <Tabs defaultValue="onaybekleyenler">
          <TabsList style={{width:"100%"}}>
            <TabsTrigger value="onaybekleyenler">Onay Bekleyenler</TabsTrigger>
            <TabsTrigger value="uretimdekiler">Üretimdekiler</TabsTrigger>
            <TabsTrigger value="sevkedilenler">Sevk Edilenler</TabsTrigger>
            <TabsTrigger value="beklemedekiler">Beklemedekiler</TabsTrigger>
            <TabsTrigger value="iptaledilenler">İptal Edilenler</TabsTrigger>
            <div style={{display:"flex",justifyContent:"flex-end",width:"100%"}}>
            <div style={{maxWidth:400}}>
            <Search
            placeholder="Ara..."
            allowClear
            size="large"
            value={searchValue}
            onChange={handleSearch}
            className="ml-auto" // Sağa hizalanması için ml-auto sınıfını ekliyoruz
        />
            </div>
            </div>
          </TabsList>
          <div className="grid grid-cols-[1fr_1fr_2fr_repeat(6,1fr)_80px] gap-x-5 text-sm text-simsek font-semibold px-5 mt-5">
            <p>Sipariş Tarih</p>
            <p>Sipariş No</p>
            <p>Müşteri Adı</p>
            <p>Marka Order No</p>
            <p>Supplier No</p>
            <p>Artıkel No</p>
            <p>Marka/Departman</p>
            <p>Made In</p>
            <p>Beden Tablosu</p>
          </div>
          <TabsContent value="onaybekleyenler">
            <AwaitingApproval />
          </TabsContent>
          <TabsContent value="uretimdekiler">
            <InProduction />
          </TabsContent>
          <TabsContent value="sevkedilenler">
            <Dispatched />
          </TabsContent>
          <TabsContent value="beklemedekiler">
            <Wait />
          </TabsContent>
          <TabsContent value="iptaledilenler">
            <Canceled />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}