import React, { useRef, useEffect, useState } from 'react'
import Barcode from 'react-barcode'

import LabelCard from '../LabelCard'
import { GrupoLogo } from 'assets/icons'
import useOrderStore from 'store/client/orderStore'
import { ReactComponent as Zarafiyat } from '../../../../../assets/zarafiyat.svg'
import QrSvg from '@wojtekmaj/react-qr-svg'
import tshirtlogo from '../../../../../assets/tshirtinfo.svg'
import QRCodeStyling from 'qr-code-styling'
import { ReactComponent as MySVG } from '../../../../../assets/fsc.svg'
import { ReactComponent as MySVG2 } from '../../../../../assets/logolar-02.svg'
import { ReactComponent as MySVG3 } from '../../../../../assets/logolar-03.svg'
import { ReactComponent as MySVG4 } from '../../../../../assets/logolar-04.svg'
import { ReactComponent as MySVG5 } from '../../../../../assets/logolar-05.svg'
import { ReactComponent as MySVG6 } from '../../../../../assets/Varlık1.svg'
import { ReactComponent as ALARM } from '../../../../../assets/alarm (1).svg'
import { ReactComponent as MySVG7 } from '../../../../../assets/pricetopsvg/MAN.svg'
import { ReactComponent as MySVG8 } from '../../../../../assets/pricetopsvg/GLOBAL.svg'
import { ReactComponent as MySVG9 } from '../../../../../assets/pricetopsvg/DENIMWEAR.svg'
import { ReactComponent as MySVG10 } from '../../../../../assets/pricetopsvg/WOMANBASICKARE.svg'
import { ReactComponent as MySVG11 } from '../../../../../assets/pricetopsvg/WOMANBASICCIRCLE.svg'
import { ReactComponent as MySVG12 } from '../../../../../assets/pricetopsvg/WOMANBASICTRIANGLE.svg'
import { ReactComponent as MySVG13 } from '../../../../../assets/pricetopsvg/KIDSBABYBOY.svg'
import { ReactComponent as MySVG14 } from '../../../../../assets/pricetopsvg/KIDSBABYGIRL.svg'
import { ReactComponent as MySVG15 } from '../../../../../assets/pricetopsvg/KIDSBOYKARE.svg'
import { ReactComponent as MySVG16 } from '../../../../../assets/pricetopsvg/KIDSGIRLDAIRE.svg'
import { ReactComponent as MySVG17 } from '../../../../../assets/pricetopsvg/KIDSBABYUNIT.svg'

import { ReactComponent as MySVG18 } from '../../../../../assets/altbestlogo.svg'
import { ReactComponent as MySVG19 } from '../../../../../assets/talla.svg'
import { ReactComponent as MySVG20 } from '../../../../../assets/usuk.svg'
import { ReactComponent as MySVG249 } from '../../../../../assets/justus.svg'
import { ReactComponent as MySVG250 } from '../../../../../assets/justuk.svg'
import { ReactComponent as MySVG21 } from '../../../../../assets/usamexuk.svg'
import { ReactComponent as MySVG22 } from '../../../../../assets/lineline.svg'
import { ReactComponent as ZaraBomRed } from '../../../../../assets/DDRED.svg';

import { ReactComponent as BlackDenim } from '../../../../../assets/black-denimwear.svg' // SVG'yi React bileşeni olarak içe aktarma
import { IoTriangleOutline } from 'react-icons/io5'
import { IoTriangle } from 'react-icons/io5'
import { LiaCircleSolid } from 'react-icons/lia'
import { BiSolidCircle } from 'react-icons/bi'
import DynamicShrinkText from './DynamicShrinkText'
import QRCodeComponent from './qrcodecomponent'
import RenderIndoItems from './RenderIndoItems'



export default function Page1({
  sizeData,
  selectColor = 0,
  barcodeValue,
  data,
  indo,
  inx,
  szrgrp,
  integerpart3,
  currencysymbol3,
  integerpart4,
  currencysymbol4,
  decimalpart3,
  decimalpart4,
  rifd
}) {
  const divRef = useRef(null)
  const textRef = useRef(null)
  const [scaleX, setScaleX] = useState(1) // Yatay daralma oranı (başlangıç 1)
  const department = data.department
  const barcodeRef = useRef(null);
  const [modifiedSvgText, setModifiedSvgText] = useState("");
  
  // Bölmek için "/" karakterini kullan
  const [brand, category, subCategory] = department.split(' / ')
  const filteredIndo = indo.filter((item) => item.amount !== '')
  const indexedIndo = indo.reduce((acc, item, index) => {
    if (item.amount !== '') {
      acc.push(index)
    }
    return acc
  }, [])

  useEffect(() => {
    if (subCategory) {
      setCurrentShape(subCategory)
    }
  }, [subCategory])

  const handleSvgGenerated = (svgText) => {
    setModifiedSvgText(svgText);
  };

  useEffect(() => {

    // Belirtilen seçiciyle DOM öğesini yakalama
    const textElement = document.querySelector(
      '#root > div.min-h-screen.font-poppins > div > div:nth-child(3) > div:nth-child(1) > div > div > div > div:nth-child(3) > div:nth-child(3) > div.printable > div > div > div > div.tracking-\\[\\.02rem\\] > svg > g > text'
    );

    if (textElement) {
      // Margin top değerini güncelle
      //textElement.style.marginTop = '-10px ';
    } else {
    }
  }, []); // Sadece bileşen yüklendiğinde bir kez çalışacak

  const renderSVG = (integer3, decimal3, integer4, decimal4) => {
    console.log("Values received:", { integer3, decimal3, integer4, decimal4 });
  
    // Değerleri stringe çevirip kontrol ediyoruz
    const isInteger3Valid = String(integer3).trim() !== '';
    const isDecimal3Valid = String(decimal3).trim() !== '';
    const isInteger4Valid = String(integer4).trim() !== '';
    const isDecimal4Valid = String(decimal4).trim() !== '';
  
    switch (true) {
      case isInteger3Valid && isDecimal3Valid && isInteger4Valid && isDecimal4Valid:
        console.log("Case 1: All values are present (integer3, decimal3, integer4, decimal4)");
        return <MySVG20 width="21.256mm" height="14.496mm" />;
      case isInteger3Valid && isDecimal3Valid && !isInteger4Valid :
        console.log("Case 2: Only integer3 and decimal3 are present, integer4 and decimal4 are missing");
        return <MySVG249 width="21.256mm" height="14.496mm" />;
      case isInteger4Valid && isDecimal4Valid && !isInteger3Valid:
        console.log("Case 3: Only integer4 and decimal4 are present, integer3 and decimal3 are missing");
        return <MySVG250 width="21.256mm" height="14.496mm" />;
      default:
        console.log("Default Case: None of the conditions are met", { integer3, decimal3, integer4, decimal4 });
        return null; // Hiçbir koşul sağlanmazsa hiçbir şey döndürme
    }
  };
  

  const renderShape = (currentShape, category) => {
    if (category === 'Woman') {
      switch (currentShape) {
        case 'WOMAN':
          return (
            <MySVG11 
            style={{
              width: '9.4mm',
              height: '9.4mm',

            }}


          />
          )
        case 'PREMIUM DENIM':
          return (
            <MySVG11 
            style={{
              width: '9.4mm',
              height: '9.4mm',
            }}

          />
          )
        case 'CIRCULAR WOMAN':
          return (
            <MySVG11 
            style={{
              width: '9.4mm',
              height: '9.4mm',
            }}
          />
          )
        case 'BASIC':
          return (
            <MySVG10 
            style={{
              width: '8.4mm',
              height: '8.4mm',
            }}
          />
          )
        case 'TRF':
          return (
            <MySVG12 
            style={{
              width: '12.4mm',
              height: '10.4mm',
            }}
          />
          )
        default:
          return (
            <div
              style={{
                width: '6mm',
                height: '6mm',
                border: '1px solid red',
                backgroundColor: 'pink',
                position: 'absolute',
                top: '30px',
                left: '50%',
                transform: 'translateX(-50%)'
              }}
            >
              Default Shape
            </div>
          )
      }
    } else if (category === 'Man') {
      switch (currentShape) {
        case 'DENIMWEAR':
          return (
            <MySVG9 
            style={{
              width: '12.4mm',
              height: '10.4mm',
            }}
          />
          )

        case 'MAN':
          return (
            <MySVG7 
            style={{
              width: '8.4mm',
              height: '8.4mm',
            }}
          />
          )

        case 'GLOBAL':
          return (
            <MySVG8 
            style={{
              width: '9.4mm',
              height: '9.4mm',
            }}
          />
          )

        default:
          return (
            <div
              style={{
                width: '6mm',
                height: '6mm',
                border: '1px solid red',
                backgroundColor: 'pink',
                position: 'absolute',
                top: '30px',
                left: '50%',
                transform: 'translateX(-50%)'
              }}
            >
              Default Shape
            </div>
          )
      }
    } else if (category === 'Kids') {
      switch (currentShape) {
        case 'GIRL':
          return (
            <MySVG16 
            style={{
              width: '9.4mm',
              height: '9.4mm',
            }}
          />
          )

        case 'BABY GIRL':
          return (
            <MySVG14 
            style={{
              width: '9.4mm',
              height: '9.4mm',
            }}
          />
          )

        case 'BABY UNIQUE':
          return (
            <MySVG17 
            style={{
              width: '13.4mm',
              height: '11.4mm',
            }}
          />
          )
        case 'KNIT':
          return (
            <MySVG17 
            style={{
              width: '13.4mm',
              height: '11.4mm',
            }}
          />
          )
        case 'DENIM':
          return (
            <MySVG17 
            style={{
              width: '13.4mm',
              height: '11.4mm',
            }}
          />
          )
        case 'OUTERWEAR':
          return (
            <MySVG17 
            style={{
              width: '13.4mm',
              height: '11.4mm',
            }}
          />
          )
        case 'BOY':
          return (
            <MySVG15 
            style={{
              width: '8.4mm',
              height: '8.4mm',
            }}
          />
          )

        case 'BABY BOY':
          return (
            <MySVG13 
            style={{
              width: '8.4mm',
              height: '8.4mm',
            }}
          />
          )

        default:
          return (
            <div
              style={{
                width: '6mm',
                height: '6mm',
                border: '1px solid red',
                backgroundColor: 'pink',
                position: 'absolute',
                top: '30px',
                left: '50%',
                transform: 'translateX(-50%)'
              }}
            >
              Default Shape
            </div>
          )
      }
    } else {
      // Eğer kategori ve alt kategori eşleşmiyorsa varsayılan bir şekil döndür
      return (
        <div
          style={{
            width: '6mm',
            height: '6mm',
            border: '1px solid blue',
            backgroundColor: 'lightblue',
            position: 'absolute',
            top: '30px',
            left: '50%',
            transform: 'translateX(-50%)'
          }}
        >
          Default Category
        </div>
      )
    }
  }

  useEffect(() => {
    const adjustScale = () => {
      if (divRef.current && textRef.current) {
        const containerWidth = divRef.current.offsetWidth
        const textWidth = textRef.current.offsetWidth

        if (textWidth > containerWidth) {
          setScaleX(containerWidth / textWidth)
        } else {
          setScaleX(1)
        }
      }
    }

    adjustScale()
    window.addEventListener('resize', adjustScale) // Dinamik ekran boyutu için
    return () => window.removeEventListener('resize', adjustScale)
  }, [filteredIndo, inx])

  const base64Logo =
    'PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmci' +
    'IHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hs' +
    'aW5rIiB3aWR0aD0iMTA0IiBoZWlnaHQ9IjEwOSIgdmlld0JveD0i' +
    'MCAwIDEwNCAxMDkiPgogIDxpbWFnZSBpZD0ibG9nbyIgd2lkdGg9' +
    'IjEwNCIgaGVpZ2h0PSIxMDkiIHhsaW5rOmhyZWY9ImRhdGE6aW1n' +
    'L3BuZztiYXNlNjQsaVZCT1J3MEtHZ29BQUFBTlNVaEVVZ0FBQUdn' +
    'QUFBQnRDQVlBQUFCTjBTUVNBQUFPUVVsRVFWUjRuR0w4Ly8vL2Y0' +
    'WlJBQWZIang5blVGWldaaEFURXh2NFFHRmdZQUFBQUFELy84VE9N' +
    'UUhBTUFnQXdVOU5zQ01ERjJpSUJhSUVhWWtLZHZiT05aQytnYi9u' +
    'ei9rNWg0aEFWUmxqNE81a0psVjEzZExkckxVd00wU0VPU2Q3Nyt1' +
    'T1Q4QUxBQUQvLzJMNFB3RGc1Y3VYLzB0S1NrQTVGeXRXVkZUOHYz' +
    'UG5Ucm81Yk8zYXRXQTdzYmtsSUNDQXJtNUJBZi8vL3djQUFBRC8v' +
    'NkpyQklFaXBybTVHV2ZFb0dPUVdwQWVXb0F2WDc2QUE5N0t5b29v' +
    'dDREVTdkcTFpNTdCOWYvLy8vLy9BUUFBQVAvL29rc0VrUm94MkZJ' +
    'eFNEOG9nTzdkdTBlMk8yQ1JBaklMVjQ0aGhLMnRyZjlmdW5TSnF1' +
    'R0RFL3ovL3g4QUFBRC8vNkpwSXdGVXJzK2JONDhoTHkrUDZtYjcr' +
    'L3N6Q0FzTE05aloyWUg1VWxKU0RCSVNFaWhxenAwN0I2WkJkUW1v' +
    'OGo5MjdCalY3QWZWVWZuNStReTZ1cnBVTXhNRE1EQXdBQUFBQVAv' +
    'L29ra08rdnIxNi84RkN4YVFuVXFIRWdibFJwQi9hUUwrLy84UEFB' +
    'QUEvLytpZWdTQmlpRlNJd1pVaElFaWREQkVETWp0K0Jvd3VQU3NX' +
    'Yk9HK25IMC8vOS9BQUFBQVAvL29sb0VYYjU4R1J6UXBIZ01WUEVp' +
    'dDVCQVpUdW9qQitvaUFFbEVsQTlCUUtnZXJPbHBZWGtoQVlLQjZx' +
    'Qi8vLy9Bd0FBQVAvL29qaUNRTm03dUxpWTVJZzVmdnc0VGpOQnVa' +
    'RFV5Q1lYZ3hJRXFKa05peGgwUUU0RFo5S2tTVGpOSXduOC8vOGZB' +
    'QUFBLy8raUtJSkFIbE5RVUNEYTRhREFJS1dwQ3ZJa0tGZUJVblp5' +
    'Y2pMVjZqUllrWHIzN2wyaTNVS283NFl0RVZLY20vNy8vdzhBQUFE' +
    'Ly95S3JGWGYvL24yR29xSWloZzBiTmhDbDN0cmFtcUd1cm83Qnpj' +
    'Mk40a1lOcUdWNDc5NDlodWZQbjRQeDQ4ZVB3ZTRCalVTQUFMSjNR' +
    'R0lDQWdMZ2xoWXZMeStEcXFvcXhhMHVVSXNRNUJkaS9UNXAwaVNH' +
    'cEtRa0JtNXVidEl0WTJCZ0FBQUFBUC8vSWprSGtWS1pvNWZyd3du' +
    'Z0czM0FsbVBKNnIvOS8vOGZBQUFBLy84aU9vSkFXWnlVZWdGVXdR' +
    'N0hpRUVHb1BxWGxQb0pGS2trZ2YvLy93TUFBQUQvL3lJcWdraHBP' +
    'b1BxQ25yMnRBY0RBUG1YMkNFalVEMUdkTUw5Ly84L0FBQUEvLy9D' +
    'RzBFZ2c0aE5JYlRzQ3d3RkFBb3JVT3VOMk1ZU1VVWGUvLy8vQVFB' +
    'QUFQLy93aGxCSUFPSUxkSkF6ZXpoWHB3UkMwanB5eEVjSmYvLy96' +
    'OEFBQUQvLzhJYVFjZU9IU09xU0FPcEFha2RCYWdBVkRlVkZCUFhK' +
    'QWVWVURnVDkvLy8vd0VBQUFELy84S0lJRkI5TTVwcnFBTkFPWVNZ' +
    'aEE0cXFiQ081LzMvL3g4QUFBRC8vMEtKSUdLR05rQVdrdE1hR2Fu' +
    'ZzFhdFhSRlVWb0hERnFKZisvLzhQQUFBQS8vK0NSeEF4alFGL2Yz' +
    'K0s1bU5HTWlDMnNZVXkwdkwvLzM4QUFBQUEvLzhDUnhBeG5jL1JJ' +
    'bzF5QUFwOFlvYkc0STJILy8vL0F3QUFBUC8vWWdCMVFBbHBBRVhn' +
    'S0tBT0FKVkF4UFNad0gzSi8vLy9Bd0FBQVAvL1lpRFVkaCtJZWZq' +
    'aERrQWxFYWhEankvY1FaSDQvLy8vL3dBQUFBRC8vd0lONU9Hc3RF' +
    'Z1o3UjBGcEFOQzlkSzllL2YrQXdBQUFQLy9Zb0tOQXFPRCt2cDY4'

  const valueBasedOnInx = indexedIndo[inx]

  const [currentShape, setCurrentShape] = useState(null)

  const { referance } = useOrderStore()
  const isBlack = referance?.backgroundColor === 'B'
  let shape



  return (
    <div>
      <div>
        <div>
          <div
            style={{
              position: 'absolute',
              top: -4.2,
              left: 8.2,

              justifyContent: 'center',
              display: 'grid',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            {rifd && (
                <ALARM width="20.4mm" height="20.1mm" />
            )}
          </div>
          <div style={{


position: 'absolute',
top: '26px',
left: '107.5px',
zIndex:4
}}>
<ZaraBomRed width="4.4mm" height="4.4mm"></ZaraBomRed>
</div>
          <div
            style={{
              position: 'absolute',
              top: '16px',
              left: '53%',
              transform: 'translateX(-50%)',
              display: 'flex', // Flexbox kullanımı
    justifyContent: 'center', // Yatayda ortalama
    alignItems: 'center', // Dikeyde ortalama
            }}
          >

    


            {renderShape(currentShape, category)}
          </div>
          <div></div>
   
   <div
            style={{
              position: 'absolute',
              top: 288.6,
              left: 67,
              flexDirection: 'column',
              display: 'grid'
            }}
          >
  {renderSVG(integerpart3, decimalpart3, integerpart4, decimalpart4)}
          </div>
          <div
            style={{
              position: 'absolute',
              top:  integerpart3 === '' ? 310 : 282.6, // Koşullu top değeri
              left: 119,
              flexDirection: 'column',
              display: 'grid'
            }}
          >
     
            <div style={{ display: 'grid' }}>
              <div style={{ display: 'grid' }}>
                {integerpart3 && (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
               
                      <p className="helve-medium" style={{ fontSize: '14pt' }}>
                        {integerpart3}
                      </p>
                      <p
                        className="helve-medium"
                        style={{ fontSize: '9pt', marginTop: '3px', marginRight: '4px' }}
                      >
                        {decimalpart3}
                      </p>
                    </div>
                    <div>
              
                    </div>
                  </>
                )}
              </div>
            </div>
            <div style={{ display: 'grid', marginTop: 4 }}>
              {integerpart4 && (
                <>
  

                  <div style={{ display: 'flex', flexDirection: 'row' }}>
               
                    <p className="helve-medium" style={{ fontSize: '14pt' }}>
                      {integerpart4}
                    </p>
                    <p
                      className="helve-medium"
                      style={{ fontSize: '9pt', marginTop: '3px', marginRight: '4px' }}
                    >
                      {decimalpart4}
                    </p>
                  </div>
                  <div>
              
                  </div>
                </>
              )}
            </div>
          </div>


          <div
            style={{
              position: 'absolute',
              top: 102.5,
              left: 80,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: 75.2,
              
            }}
          >
         <MySVG19 width="19mm" height="1,359mm" ></MySVG19>
         </div>
          <div style={{ position: 'absolute', bottom:31.4, left: 28.6 }}>

          <MySVG18 width="46.800mm" height="8mm" />
          </div>

          <div style={{ position: 'absolute', top: 0, right: 0 }}>
            <QRCodeComponent  barcodeValue={barcodeValue}  onSvgGenerated={handleSvgGenerated} />
            {modifiedSvgText && (
              <div
  dangerouslySetInnerHTML={{ __html: modifiedSvgText }}

></div>

      )}
          </div>
          <DynamicShrinkText
            filteredIndo={filteredIndo}
            inx={inx}
            szrgrp={szrgrp}
            valueBasedOnInx={valueBasedOnInx}
            referance={referance}
            brand={brand}
            category={category}
            subCategory={subCategory}
          />
          <div style={{position:"absolute",top:120,left:87}}>
           <MySVG21 width="24.602mm" height="1.33mm"></MySVG21>
           </div>
           <div style={{position:"absolute",top:110.5,left:45.5}}>
           <MySVG22 width="36.999mm" height="10.414mm"></MySVG22>
           </div>
          <div ref={barcodeRef} className="tracking-[.02rem]" style={{ position: 'absolute', top: 207, left: 51.5,width:"35mm",height:"10.310mm"}}>
            <Barcode
              value={barcodeValue.length === 13 ? barcodeValue : '0390547180002'}
              format="ITF14"
              width={0.936}
              height={25.9}
              marginLeft={1}
              marginRight={1}
              marginTop={1}
              marginBottom={1}
              background="transparent"
              lineColor={isBlack ? '#ffffff' : '#000000'}
              fontSize={9} // 7pt yaklaşık olarak 9px
              font="Helvetica Neue LT Std Roman"
            />
          </div>

          <div style={{ position: 'absolute', top: 155, left: 11,width:"95.5%" }}>
            <div
              style={{ display: 'flex', justifyContent: 'center', textAlign: 'center',width:"100%" }}
            >

<RenderIndoItems indo={filteredIndo} startIndex={valueBasedOnInx} indexxx={indexedIndo} />


            </div>
          </div>
          <div style={{ position: 'absolute', top: 192.4, left: 71.5 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p className="helve-roman" style={{ fontSize: '7pt', marginRight: '1px' }}>
                ART
              </p>  
              <p className="helve-roman" style={{ fontSize: '7pt', marginRight: '8px' }}>
                .:
              </p>
              <p className="helve-roman" style={{ fontSize: '7pt', marginLeft: '-7px' }}>
                {`${data.articleNo}/${data.priceLabel[selectColor].colorCode}`}
              </p>
            </div>{' '}
          </div>
        </div>
      </div>
    </div>
  )
}
