import requests from 'service/api'
import LabelCard from './LabelCard'
import { useQuery } from '@tanstack/react-query'
import useOrderStore from 'store/client/orderStore'
import { useEffect } from 'react'

export default function MultiFabricLabelsYatay({ data }) {
  const { setLabelCount } = useOrderStore()
  const fabricCount = data?.washingLabel[0].fabric.length
  useEffect(() => {
    if (fabricCount > 0) {
      setLabelCount(fabricCount === 1 ? 1 : fabricCount * 2)
    }
    // eslint-disable-next-line
  }, [fabricCount])

  return data?.washingLabel[0].fabric.map((fabric, idx) => {
    const mixture = fabric.mix.filter((item) => item.percent > 0)
    const mixtureCount = mixture.length
    return (
      <>
        {fabric.name && (
          <LabelCard
            type="front"
            number={idx > 0 ? 3 + idx * 2 : 3}
            className="font-arialMs text-[5.4pt]"
          >
            <div className="flex items-center h-full">
              <FabricLabelItem data={fabric.name} />
            </div>
          </LabelCard>
        )}
        {mixtureCount <= 2 && (
          <LabelCard
            type={fabric.name ? 'back' : fabricCount === 1 ? 'front' : 'back'}
            number={!fabric.name ? 3 : idx > 0 ? 3 + idx * 2 : 4}
            className="font-arialMs text-[5.4pt]"
          >
            <div className="flex items-center h-full">
              <div className="font-arialMs text-[5pt] scale-y-[139%] leading-[5.2pt] pl-[3.5mm] pr-[2.5mm] ">
                {mixture.map((fabric, idx) => (
                  <LabelItem data={fabric} key={idx} />
                ))}
              </div>
            </div>
          </LabelCard>
        )}
      </>
    )
  })
}

function LabelItem({ data: newData }) {
  const { data: fabricLangs } = useQuery({
    queryKey: ['/list', 'fabricTranslate', newData.type],
    queryFn: async () => {
      const { data } = await requests.fabric.lang(newData.type)
      return data
    },
    placeholderData: [],
    enabled: !!newData.type
  })

  const uniqueLanguages = new Set(fabricLangs?.translate?.map((lang) => lang.translation))

  const languagesWithDashes = Array.from(uniqueLanguages).slice(0, 13).join(' - ')
  return (
    <p className="flex gap-x-1">
      <span>{newData.percent}%</span>
      {languagesWithDashes}
    </p>
  )
}

function FabricLabelItem({ data: type }) {
  const { data: fabricLangs } = useQuery({
    queryKey: ['/list', 'fabricTranslate', type],
    queryFn: async () => {
      const { data } = await requests.fabric.lang(type)
      return data
    },
    placeholderData: []
  })

  const uniqueLanguages = new Set(fabricLangs?.translate?.map((lang) => lang.translation))

  const languagesWithDashes = Array.from(uniqueLanguages).slice(0, 13).join(' - ')

  return (
    <p className="font-arialMs text-[5.4pt] scale-y-[139%] leading-[6.5pt] px-[2.5mm]">
      {languagesWithDashes}
    </p>
  )
}
