import { Formik, Form } from 'formik'

import { useLogin } from 'store/server/useAuth'

import { Input } from 'components/form/input'
import { Button } from 'components/ui/button'
import { ReloadIcon } from '@radix-ui/react-icons'

export default function LoginForm() {
  const { mutate, isPending } = useLogin()

  const handleSubmit = async (values) => {
    mutate(values)
  }

  return (
    <Formik
      initialValues={{
        mail: '',
        password: ''
      }}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form className="space-y-5">
          <Input label="Mail" name="mail" />
          <Input label="Şifre" name="password" type="password" />
          <Button type="submit" className="w-full" disabled={isPending}>
            {isPending && <ReloadIcon className="w-5 h-5 mr-2 -ml-1 animate-spin" />}
            Giriş Yap
          </Button>
        </Form>
      )}
    </Formik>
  )
}
