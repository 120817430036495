import { create } from 'zustand'

const useOrderStore = create((set) => ({
  referance: null,
  labelCount: 0,
  fabricName: false,

  setFabricName: (fabricName) => set({ fabricName }),
  setReferance: (referance) => set({ referance }),
  setLabelCount: (labelCount) => set({ labelCount })
}))

export default useOrderStore
