import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import requests from 'service/api'

import SearchSelect from '../search-select'

export function SelectWashingAdditionalInstructions({ name, idx, idx2 }) {
  const { values } = useFormikContext()
  const [selected, setSelected] = useState(null)

  const { data, isFetched } = useQuery({
    queryKey: ['/list', 'washingAdditionalPhrases'],
    queryFn: async () => {
      const response = await requests.additionalPhrases.washList()
      return response.data
    },
    placeholderData: []
  })

  const options = data.map(({ id, name }) => ({
    label: name,
    value: id
  }))

  useEffect(() => {
    if (values.washingLabel[0].washingAdditionalPhrases && isFetched) {
      const select = options.find(
        ({ value }) => value === values.washingLabel[idx].washingAdditionalPhrases[idx2].id
      )
      setSelected(select)
    }
    // eslint-disable-next-line
  }, [values.washingLabel[0].washingAdditionalPhrases, isFetched])

  return (
    <SearchSelect options={options} name={name} value={selected} onChange={(e) => setSelected(e)} />
  )
}
