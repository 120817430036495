import { useQuery } from '@tanstack/react-query'

import requests from 'service/api'

import SearchSelect from '../search-select'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

export function SelectFabricType({ name, idx, idx2, idx3 }) {
  const { values } = useFormikContext()
  const [selected, setSelected] = useState(null)
  const path = values.washingLabel[idx].fabric[idx2].mix[idx3].type

  const { data, isFetched } = useQuery({
    queryKey: ['/list', 'fabricType'],
    queryFn: async () => {
      const { data } = await requests.fabric.typeList()
      console.log(data);
      return data
    },
    placeholderData: []
  })

  const options = data.map(({ id, name }) => ({
    value: name,
    label: name
  }))
  useEffect(() => {
  }, [selected])


  useEffect(() => {
    if (path.length > 0 && isFetched) {
      const select = options.find(({ value }) => value === path)
      setSelected(select)
    }
    // eslint-disable-next-line
  }, [path, isFetched])

  return (
    <SearchSelect name={name} options={options} value={selected} onChange={(e) => setSelected(e)} />
  )
}
